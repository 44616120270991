import { FC } from 'react'

import { Button, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

interface IProps extends IModalWithCloseFn {
  onCancel: () => void
  email: string
  loading: boolean
}

const CancelInviteModal: FC<IProps> = (props) => {
  const { closeModal, onCancel, email, loading } = props

  return (
    <Modal
      size="md"
      title="Delete User"
      cancelButtonText="Keep Invite"
      closeModal={closeModal}
      footer={
        <Button type="danger" onClick={onCancel} disabled={loading}>
          {loading ? 'Cancelling...' : 'Cancel Invite'}
        </Button>
      }
    >
      <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
        Are you sure you want to cancel the invite sent to <b>{email}</b>?{' '}
        <br />
        <br />
        This will revoke their invitation, and the user will not be able to
        access their account.
      </Text>
    </Modal>
  )
}

export default CancelInviteModal
