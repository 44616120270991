import { faker } from '@faker-js/faker'

import { IManualEntry } from '@/features/mismatches/types'

const manualEntriesMockData: IManualEntry[] = [
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',
    puLPN: '54819E3',
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    userId: '252354'
  },
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',
    puLPN: '54819E3',
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    userId: '6463452'
  }
]

export default manualEntriesMockData
