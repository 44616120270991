import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'

const discardedDetectionsColumns = (): ITableColumn[] => [
  {
    id: 'transactionType',
    title: 'Transaction Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'transactionTime',
    title: 'Transaction Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'reason',
    title: 'Reason',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'images',
    title: 'Images',
    type: TableColumnType.Action,
    actionName: 'View'
  }
]

export default discardedDetectionsColumns
