import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CheckOutCargoAssetFormSection,
  CheckOutPowerUnitFormSection,
  DriverDetailsFormSection
} from '@/features/gate'
import { IGateQueueItem } from '@/features/gate/types'
import { CheckOutSchema, isCargoAssetVisible } from '@/features/gate/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { replaceNullToUndefined } from '@/utils/helpers'

interface IProps extends IModalWithCloseFn {
  item?: IGateQueueItem
}

const CheckOutModal: FC<IProps> = (props) => {
  const { item, closeModal } = props
  const { returnVisit, date } = item || {}

  const isManually = !item

  const formReturn = useForm({
    resolver: yupResolver(CheckOutSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: item
      ? {
          ...replaceNullToUndefined(item),

          date: dayjs(date || new Date()),
          time: dayjs(date || new Date()),
          appointmentType: AppointmentTypes.DROP
        }
      : undefined
  })

  const { watch, trigger } = formReturn

  const unitType = watch('puType')
  const appointmentType = watch('appointmentType')

  const showCargoAsset = isCargoAssetVisible(appointmentType, unitType, false)

  const onSubmit = () => {
    trigger()
  }

  return (
    <Modal
      title="Check-Out"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      footer={
        <Button action="submit" onClick={onSubmit} type="primary">
          Complete Check-Out
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            item ? (
              <FormAside
                img={item.img}
                lane={item.lane}
                returnVisit={!!returnVisit}
              />
            ) : undefined
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Power Unit">
              <CheckOutPowerUnitFormSection />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection readOnly={!isManually} />
            </FormSection>

            {showCargoAsset && (
              <FormSection title="Cargo Asset">
                <CheckOutCargoAssetFormSection />
              </FormSection>
            )}
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CheckOutModal
