import IosShareIcon from '@mui/icons-material/IosShare'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Col, HeaderActionPortal } from '@/components/atoms'
import { Stats } from '@/components/molecules'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { IGateTransaction } from '@/features/gate/types'
import {
  generateTransactionsStats,
  transactionsColumns,
  transactionsFilters,
  transactionsHighOrderColumns,
  transactionsMockData
} from '@/features/gate/utils'
import { IStatsItem } from '@/types/interfaces/ui'

const GateTransactionTemplate = () => {
  const [searchParams] = useSearchParams()

  const appointmentTypeFilter = searchParams.get('appointmentType')

  const searchFields: (keyof IGateTransaction)[] = [
    'direction',
    'eventType',
    'appointmentType',
    'puName',
    'puLPN',
    'usDOT',
    'mcNum',
    'puVIN',
    'puClass',
    'puFuelType',
    'operatingCarrier',
    'account',
    'driverName',
    'driverLicenseNum',
    'driverPhoneNum',
    'assetName',
    'assetLPN',
    'assetCarrier',
    'assetType',
    'loadStatus',
    'shipmentNumber',
    'sealNumber',
    'source'
  ]

  const defaultFilters = useMemo(() => {
    const values: any = {}

    if (appointmentTypeFilter) {
      values.appointmentType = [appointmentTypeFilter]
    }

    return Object.keys(values).length ? values : undefined
  }, [appointmentTypeFilter])

  const statsItems: IStatsItem[] = useMemo(
    () => generateTransactionsStats(transactionsMockData),
    []
  )

  return (
    <WithHeaderTemplate title="Transactions">
      <Col items="stretch" className="tw-h-full">
        <HeaderActionPortal>
          <Button type="primary" startIcon={<IosShareIcon />}>
            Export
          </Button>
        </HeaderActionPortal>

        <Stats items={statsItems} className="tw-grid-cols-7" />

        <div className="tw-flex-1 tw-flex">
          <Table
            name="gate-transactions"
            idAccessor="id"
            rows={transactionsMockData}
            searchPlaceholder="Search Transaction"
            searchFields={searchFields}
            filters={transactionsFilters}
            defaultFilters={defaultFilters}
            columns={transactionsColumns}
            highOrderColumns={transactionsHighOrderColumns}
          />
        </div>
      </Col>
    </WithHeaderTemplate>
  )
}

export default GateTransactionTemplate
