import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import {
  Button,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select
} from '@/components/atoms'
import {
  CheckOutSchema,
  suggestedPuLPN,
  suggesterPowerUnitNames
} from '@/features/gate/utils'
import { statesOptions } from '@/utils/data'
import {
  accountsSelectOptions,
  carriersSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions,
  powerUnitTypeSelectOptions
} from '@/utils/mappers'

const CheckOutPowerUnitFormSection: FC = () => {
  const {
    watch,
    setValue,
    register,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckOutSchema>>()

  const isManually = !watch('id')

  const [showReadOnlyFields, setShowReadOnlyFields] = useState(false)
  const [animationInProgress, setAnimationInProgress] = useState(false)

  const puName = watch('puName')
  const puLPN = watch('puLPN')
  const puType = watch('puType')
  const puLPNState = watch('puLPNState')
  const usDOT = watch('usDOT')
  const operatingCarrier = watch('operatingCarrier')
  const puClass = watch('puClass')
  const puFuelType = watch('puFuelType')
  const puVIN = watch('puVIN')
  const account = watch('account')

  const toggleDetails = () => {
    setShowReadOnlyFields(!showReadOnlyFields)

    if (showReadOnlyFields) {
      setAnimationInProgress(true)
    }
  }

  return (
    <Col items="stretch" gap={20}>
      {isManually ? (
        <Select
          required
          label="Type"
          value={puType}
          error={!!errors.puType}
          helperText={errors.puType?.message}
          options={powerUnitTypeSelectOptions}
          onChange={(value) => setValue('puType', value as string)}
        />
      ) : (
        <ReadOnlyFormValue title="Type" value={puType} />
      )}

      <Select
        required
        label="ID"
        value={puName}
        options={suggesterPowerUnitNames}
        error={!!errors.puName}
        helperText={errors.puName?.message}
        onChange={(value) => setValue('puName', value as string)}
      />

      <Row gap={16}>
        <Select
          required
          label="LPN"
          value={puLPN}
          options={suggestedPuLPN}
          error={!!errors.puLPN}
          helperText={errors.puLPN?.message}
          onChange={(value) => setValue('puLPN', value as string)}
        />

        {isManually ? (
          <Select
            required
            label="LPN State/Region"
            value={puLPNState}
            options={statesOptions}
            error={!!errors.puLPNState}
            helperText={errors.puLPNState?.message}
            onChange={(value) => setValue('puLPNState', value as string)}
          />
        ) : (
          <ReadOnlyFormValue title="LPN State/Region" value={puLPNState} />
        )}
      </Row>

      <Collapse
        in={showReadOnlyFields || isManually}
        unmountOnExit
        onExited={() => setAnimationInProgress(false)}
        className={clsx(
          animationInProgress && '!tw-mt-[-20px] tw-relative tw-top-[20px]'
        )}
      >
        <Col gap={20}>
          {isManually ? (
            <Row gap={16}>
              <Input
                {...register('usDOT')}
                required
                fullWidth
                digitsOnly
                type="number"
                label="US DOT #"
                error={!!errors.usDOT}
                helperText={errors.usDOT?.message}
              />
              <Select
                required
                label="Carrier"
                value={operatingCarrier}
                options={carriersSelectOptions}
                error={!!errors.operatingCarrier}
                helperText={errors.operatingCarrier?.message}
                onChange={(value) =>
                  setValue('operatingCarrier', value as string)
                }
              />
            </Row>
          ) : (
            <Row gap={16}>
              <ReadOnlyFormValue title="US DOT #" value={usDOT} />
              <ReadOnlyFormValue title="Carrier" value={operatingCarrier} />
            </Row>
          )}

          {isManually ? (
            <Row gap={16}>
              <Select
                required
                label="Class"
                value={puClass}
                options={classListSelectOptions}
                error={!!errors.puClass}
                helperText={errors.puClass?.message}
                onChange={(value) => setValue('puClass', value as string)}
              />
              <Select
                required
                label="Fuel Type"
                value={puFuelType}
                options={fuelTypeSelectOptions}
                error={!!errors.puFuelType}
                helperText={errors.puFuelType?.message}
                onChange={(value) => setValue('puFuelType', value as string)}
              />
            </Row>
          ) : (
            <Row gap={16}>
              <ReadOnlyFormValue title="Class" value={puClass} />
              <ReadOnlyFormValue title="Fuel Type" value={puFuelType} />
            </Row>
          )}

          {isManually ? (
            <Row gap={16}>
              <Input
                {...register('puVIN')}
                uppercase
                fullWidth
                label="VIN"
                error={!!errors.puVIN}
                helperText={errors.puVIN?.message}
              />

              <Select
                multiple
                label="Account"
                value={account}
                options={accountsSelectOptions}
                error={!!errors.account}
                helperText={errors.account?.message}
                onChange={(value) => setValue('account', value as string[])}
              />
            </Row>
          ) : (
            <Row gap={16}>
              <ReadOnlyFormValue title="VIN" value={puVIN} />
              <ReadOnlyFormValue title="Account" value={account?.toString()} />
            </Row>
          )}
        </Col>
      </Collapse>

      {!isManually && (
        <Button
          type="text"
          className="tw-self-start !tw-px-0"
          endIcon={
            showReadOnlyFields ? (
              <KeyboardArrowUpOutlinedIcon />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )
          }
          onClick={toggleDetails}
        >
          {showReadOnlyFields ? 'Hide Details' : 'Show Details'}
        </Button>
      )}
    </Col>
  )
}

export default CheckOutPowerUnitFormSection
