import clsx from 'clsx'
import { FC } from 'react'

import { ReactComponent as TerminalLogo } from '@/assets/icons/terminal-logo.svg'
import { ReactComponent as TerminalBigLogo } from '@/assets/icons/terminal-wide-logo.svg'

import styles from './Logo.module.scss'

interface IProps {
  size?: number
  mini?: boolean
  className?: string
  onClick?: () => void
  color?: 'white' | 'green' | 'gray'
}

const Logo: FC<IProps> = (props) => {
  const { size = 40, mini = true, onClick, color = 'white', className } = props

  return (
    <div
      onClick={onClick}
      className={clsx(className, styles.logoWrapper, styles[color])}
    >
      {mini ? (
        <TerminalLogo width={size} height={size} />
      ) : (
        <TerminalBigLogo width={size} height="auto" />
      )}
    </div>
  )
}

export default Logo
