import { faker } from '@faker-js/faker'

import { TRUCK_IMAGES } from '@/constants'
import { IGateQueueItem } from '@/features/gate/types'
import {
  Accounts,
  CargoAssetTypes,
  Carriers,
  Classes,
  FuelTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'

export const checkInItems: IGateQueueItem[] = [
  {
    id: '1',

    img: TRUCK_IMAGES[0],
    date: new Date(),
    returnVisit: true,
    lane: 1,

    duration: 16200000,

    puLPN: '783CCL3',
    puClass: Classes.Class8,
    assetName: faker.datatype
      .number({ min: 100000000, max: 999999999 })
      .toString(),
    puName: faker.datatype
      .number({ min: 100000000, max: 999999999 })
      .toString(),
    operatingCarrier: Carriers.Ryder,

    assetLPN: faker.vehicle.vrm(),
    chassisName: faker.datatype
      .number({ min: 100000000, max: 999999999 })
      .toString()
  },
  {
    id: '2',

    img: TRUCK_IMAGES[1],
    lane: 1,
    date: new Date(),
    returnVisit: false,

    duration: 16200000,

    puLPN: '783CCL3',
    puClass: null,
    puName: null,
    operatingCarrier: null,

    assetName: null,
    assetLPN: null,
    chassisName: null
  }
]

export const checkOutItems: IGateQueueItem[] = [
  {
    id: '3',

    lane: 2,
    date: new Date(),
    returnVisit: true,
    img: TRUCK_IMAGES[4],

    duration: 16200000,

    puLPN: '783CCL3',
    puClass: Classes.Class2b7,
    puName: '32499785291',
    puLPNState: 'CA',
    operatingCarrier: Carriers.Ryder,
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    puFuelType: FuelTypes.Diesel,
    puType: PowerUnitTypes.Tractor,
    account: [Accounts.APL],

    assetName: undefined,
    assetLPN: undefined,
    chassisName: undefined,
    assetType: CargoAssetTypes.IntermodalContainer,

    driverName: 'Misha Kliuvak',
    driverLicenseNum: 'FW325235',
    driverPhoneNum: faker.phone.number('+1 212 ###-####')
  },
  {
    id: '4',

    lane: 2,
    date: new Date(),
    img: TRUCK_IMAGES[3],
    returnVisit: false,

    duration: 16200000,

    puLPN: '783CCL3',
    puClass: Classes.Class2b7,
    puName: '32499785291',
    puLPNState: 'CA',
    operatingCarrier: Carriers.Ryder,
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    puFuelType: FuelTypes.Diesel,
    puType: PowerUnitTypes.BoxTruck,

    assetName: null,
    assetLPN: null,
    chassisName: null,

    driverName: 'Misha Kliuvak',
    driverLicenseNum: 'FW325235',
    driverPhoneNum: faker.phone.number('+1 212 ###-####')
  },
  {
    id: '5',

    img: TRUCK_IMAGES[2],
    lane: 2,
    returnVisit: false,
    date: new Date(),

    duration: 16200000,

    puFuelType: FuelTypes.Diesel,
    puType: PowerUnitTypes.Tractor,
    puLPN: '783CCL3',
    puClass: Classes.Class2b7,
    puName: '32499785291',
    operatingCarrier: Carriers.Ryder,
    puLPNState: 'CA',
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    account: [Accounts.FandT],

    assetType: CargoAssetTypes.IntermodalContainer,
    assetName: undefined,
    assetLPN: undefined,
    chassisName: undefined,

    driverName: 'Misha Kliuvak',
    driverLicenseNum: 'FW325235',
    driverPhoneNum: faker.phone.number('+1 212 ###-####')
  }
]
