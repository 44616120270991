import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import React, { FC } from 'react'

import { Row, Tooltip } from '@/components/atoms'
import { IHighOrderColumn, ITableColumn } from '@/types/interfaces/table'
import { Order } from '@/types/interfaces/ui'

import styles from './TableHeader.module.scss'

interface IProps {
  highOrderCols?: IHighOrderColumn[]
  cols: ITableColumn[]
  orderBy: string | undefined
  order: Order
  handleSort: (property: string) => void
}

const TableHeader: FC<IProps> = (props) => {
  const { highOrderCols, cols, orderBy, order, handleSort } = props

  const renderTooltip = (tooltip: string) => (
    <Tooltip placement="bottom" show tooltip={tooltip}>
      <ErrorOutlineIcon className={styles.tooltipIcon} />
    </Tooltip>
  )

  return (
    <TableHead>
      {!!highOrderCols?.length && (
        <TableRow>
          {highOrderCols.map((column) => (
            <TableCell
              key={column.id}
              colSpan={column.colSpan}
              variant="head"
              align="left"
            >
              {column.title}
            </TableCell>
          ))}
        </TableRow>
      )}

      <TableRow>
        {cols.map((column) =>
          column.sortable ? (
            <TableCell
              key={column.id}
              variant="head"
              align="left"
              sortDirection={orderBy === column.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={() => handleSort(column.id)}
              >
                {column.title}

                {column.tooltip && renderTooltip(column.tooltip)}

                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={column.id} variant="head" align="left">
              <Row items="center">
                {column.title}

                {column.tooltip && renderTooltip(column.tooltip)}
              </Row>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
