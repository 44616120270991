import { FC, ReactNode } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'

import styles from './Chart.module.scss'

interface IProps {
  title: string
  description: string | ReactNode
  value: number
}

const Chart: FC<IProps> = (props) => {
  const { title, description, value } = props

  const colorId = value < 75 ? '#red-gradient' : '#green-gradient'

  return (
    <Col gap={11} items="center" justify="between" className="tw-mx-auto">
      <Col items="center" gap={5}>
        <Text
          type={TextTypes.TEXT_MD}
          weight={FontWeight.BOLD}
          color={Color.gray700}
          align={TextAlign.CENTER}
        >
          {title}
        </Text>
        <Text
          type={TextTypes.TEXT_XS}
          color={Color.gray600}
          align={TextAlign.CENTER}
        >
          {description}
        </Text>
      </Col>

      <div className={styles.progressBarContainer}>
        <svg width="0" height="0">
          <defs>
            <linearGradient id="green-gradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#2B7048" />
              <stop offset="50%" stopColor="#449D68" />
              <stop offset="100%" stopColor="#2B7048" />
            </linearGradient>
          </defs>
        </svg>

        <svg width="0" height="0">
          <defs>
            <linearGradient id="red-gradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#DC2626" />
              <stop offset="50%" stopColor="#FCA5A5" />
              <stop offset="100%" stopColor="#DC2626" />
            </linearGradient>
          </defs>
        </svg>

        <Text
          type={TextTypes.DISPLAY_SM}
          weight={FontWeight.BOLD}
          color={Color.gray700}
          align={TextAlign.CENTER}
          className={styles.progressValue}
        >
          {value}%
        </Text>

        <CircularProgressbar
          value={value}
          strokeWidth={10}
          styles={{
            path: {
              stroke: `url(${colorId})`
            }
          }}
        />
      </div>
    </Col>
  )
}

export default Chart
