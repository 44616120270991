import { Dayjs } from 'dayjs'

const getTimeFilterValue = (value: Dayjs | null): string => {
  if (!value) return '0h'

  const hours = value.hour()
  const minutes = value.minute()

  return `over ${minutes ? `${hours}h ${minutes}m` : `${hours}h`}`
}

export default getTimeFilterValue
