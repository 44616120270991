import dayjs from 'dayjs'
import { FC } from 'react'

import { Col, ReadOnlyFormValue, Text } from '@/components/atoms'
import { IISRItem } from '@/features/isr/types'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './VisitDetailsGeneral.module.scss'

interface IProps {
  item: IISRItem
}

const VisitDetailsGeneral: FC<IProps> = (props) => {
  const { item } = props
  const { checkInTime, checkOutTime, source } = item

  const checkInDateFormatted = dayjs(checkInTime).format('MM/DD/YYYY')
  const checkInTimeFormatted = dayjs(checkInTime).format('hh:mm A')

  const checkOutDateFormatted = dayjs(checkOutTime).format('MM/DD/YYYY')
  const checkOutTimeFormatted = dayjs(checkOutTime).format('hh:mm A')

  return (
    <Col gap={40}>
      <Col gap={6}>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Check-In
        </Text>

        <div className={styles.visitGeneralItems}>
          <ReadOnlyFormValue title="Date" value={checkInDateFormatted} />
          <ReadOnlyFormValue title="Time" value={checkInTimeFormatted} />
          <ReadOnlyFormValue title="Check-In User" value={source} />
        </div>
      </Col>

      <Col gap={6}>
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Check-Out
        </Text>

        <div className={styles.visitGeneralItems}>
          <ReadOnlyFormValue title="Date" value={checkOutDateFormatted} />
          <ReadOnlyFormValue title="Time" value={checkOutTimeFormatted} />
          <ReadOnlyFormValue title="Check-Out User" value={source} />
        </div>
      </Col>
    </Col>
  )
}

export default VisitDetailsGeneral
