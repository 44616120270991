import { configureStore } from '@reduxjs/toolkit'
import { useDispatch as useDispatchReact, useSelector } from 'react-redux'

import { userStoreReducer } from '@/features/auth/store'
import { baseApi } from '@/api'

export const store = configureStore({
  reducer: {
    user: userStoreReducer,
    [baseApi.reducerPath]: baseApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export const useDispatch = useDispatchReact.withTypes<AppDispatch>()
export const useStore = useSelector.withTypes<RootState>()
