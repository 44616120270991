import { FC, useState } from 'react'
// @ts-ignore
import Lightbox from 'react-awesome-lightbox'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './FormAside.module.scss'

interface IProps {
  img: string
  lane: number
  returnVisit: boolean | undefined
}

const FormAside: FC<IProps> = (props) => {
  const { img, lane, returnVisit } = props

  const [showImagePreview, setShowImagePreview] = useState(false)

  const returnVisitValue = returnVisit ? 'Yes' : 'No'

  return (
    <>
      {showImagePreview && (
        <Lightbox image={img} onClose={() => setShowImagePreview(false)} />
      )}

      {!!img && (
        <img
          src={img}
          alt="Check-In"
          className={styles.truckImg}
          onClick={() => setShowImagePreview(true)}
        />
      )}

      <Col className={styles.metadata} gap={16}>
        <Col gap={5}>
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Lane
          </Text>
          <Text
            type={TextTypes.TEXT_LG}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {lane || '--'}
          </Text>
        </Col>

        <Col gap={3}>
          <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
            Return Visit
          </Text>
          <Text
            type={TextTypes.TEXT_LG}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray700}
          >
            {typeof returnVisit === 'boolean' ? returnVisitValue : '--'}
          </Text>
        </Col>
      </Col>
    </>
  )
}

export default FormAside
