import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  IJwt,
  IUpdatePortalsPayload,
  IUserPortalsListItem,
  IUserStore
} from '@/features/auth/types'
import { jwtDecode } from 'jwt-decode'
import { ISiteRolesResponseData } from '@/features/user-management/api/types'
import { EnterpriseRoles, PortalTypes } from '@/types/enums/global'

const initialState: IUserStore = {
  me: undefined,
  selectedPortal: undefined,
  authToken: undefined,
  org: undefined,
  sites: [],
  portals: []
}

export const userStore = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserDetails: (state, action: PayloadAction<string>) => {
      const { payload } = action

      const decodedJwt: IJwt = jwtDecode(payload)

      const {
        user_id,
        first_name,
        last_name,
        nickname,
        name,
        email,
        email_verified,
        picture,
        organization_id,
        organization_subdomain,
        organization_name
      } = decodedJwt

      state.authToken = payload
      state.org = {
        organization_id,
        organization_name,
        organization_subdomain
      }
      state.me = {
        user_id,
        first_name,
        last_name,
        nickname,
        name,
        email,
        email_verified,
        picture
      }
    },

    changeUserName: (
      state,
      action: PayloadAction<{
        first_name: string
        last_name: string
        name: string
      }>
    ) => {
      const { first_name, last_name, name } = action.payload

      if (state.me) {
        state.me = {
          ...state.me,
          first_name,
          last_name,
          name
        }
      }
    },

    updatePortalRoles: (
      state,
      action: PayloadAction<ISiteRolesResponseData>
    ) => {
      const { payload } = action
      const { site_id, roles = [] } = payload

      state.portals = state.portals.map((portal) =>
        portal.id === site_id ? { ...portal, roles } : portal
      )

      if (state.selectedPortal?.id && state?.selectedPortal.id === site_id) {
        state.selectedPortal.roles = roles
      }
    },

    updatePortals: (state, action: PayloadAction<IUpdatePortalsPayload>) => {
      const { payload } = action
      const { sites, orgRoles } = payload

      state.sites = sites

      const availablePortals: IUserPortalsListItem[] = sites.map((site) => ({
        id: site.id,
        name: site.display_name,
        type: PortalTypes.Site,
        roles: undefined
      }))

      if (orgRoles.includes(EnterpriseRoles.Admin)) {
        availablePortals.push({
          id: PortalTypes.Enterprise,
          name: 'Enterprise',
          type: PortalTypes.Enterprise,
          roles: orgRoles
        })
      }

      state.portals = availablePortals

      if (!availablePortals.length) {
        state.selectedPortal = null

        return state
      }

      const selectedPortal = sessionStorage.getItem('selected-portal')

      // Try to find the selected portal in the available portals
      const selectedPortalEntity = selectedPortal
        ? availablePortals.find((portal) => portal.id === selectedPortal)
        : undefined

      // If the selected portal is not found, default to the first available portal
      state.selectedPortal = selectedPortalEntity?.id
        ? selectedPortalEntity
        : availablePortals[0]
    },

    selectPortal: (state, action: PayloadAction<string>) => {
      const newSelectedPortal = state.portals.find(
        (portal) => portal.id === action.payload
      )

      if (newSelectedPortal) {
        state.selectedPortal = newSelectedPortal
        sessionStorage.setItem('selected-portal', newSelectedPortal.id)
      }
    },

    logoutUser: (state) => {
      state = initialState
    }
  }
})

export const userStoreReducer = userStore.reducer

export const {
  updateUserDetails,
  logoutUser,
  updatePortalRoles,
  updatePortals,
  selectPortal,
  changeUserName
} = userStore.actions
