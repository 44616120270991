import {
  IDateRangeFilterValue,
  IFilterDateRange
} from '@/types/interfaces/table'

const getDateRangeFilterValue = (
  filter: IFilterDateRange,
  value: string | IDateRangeFilterValue
): string => {
  if (typeof value === 'string') {
    const selectedOption = filter.extraOptions?.find?.(
      (option) => option.id === value
    )

    return selectedOption?.title || ''
  }

  const startDate = value?.startDate
    ? value?.startDate?.format('MM/DD/YYYY')
    : 'N/A'
  const endDate = value?.endDate ? value?.endDate?.format('MM/DD/YYYY') : 'N/A'

  return `${startDate} - ${endDate}`
}

export default getDateRangeFilterValue
