import { IMismatch } from '@/features/mismatches/types'
import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'

const mismatchesColumns = (): ITableColumn[] => [
  {
    id: 'transactionType',
    title: 'Transaction Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'transactionTime',
    title: 'Transaction Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'puLPN',
    title: 'LPN',
    danger: (row: IMismatch) => row.puLPN !== row.cvLPN,
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'cvLPN',
    title: 'CV LPN',
    danger: (row: IMismatch) => row.puLPN !== row.cvLPN,
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'usDOT',
    title: 'USDOT',
    danger: (row: IMismatch) => row.usDOT !== row.cvUsDOT,
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'cvUsDOT',
    title: 'CV USDOT',
    danger: (row: IMismatch) => row.usDOT !== row.cvUsDOT,
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'type',
    title: 'Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'images',
    title: 'Images',
    type: TableColumnType.Action,
    actionName: 'View'
  }
]

export default mismatchesColumns
