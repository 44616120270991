import { TWO_HOURS_IN_MILLISECONDS } from '@/constants'
import { IDriverItem } from '@/features/gate/types'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { IStatsItem } from '@/types/interfaces/ui'

const prepareDriversStats = (rows: IDriverItem[]): IStatsItem[] => [
  { id: 'total', title: 'Total Visitors', value: rows.length },
  {
    id: 'overTwoHours',
    title: 'Visits Over 2 Hours',
    value: rows.filter((item) => item.puDwell > TWO_HOURS_IN_MILLISECONDS)
      .length,
    filterKey: 'puDwell',
    filterValue: `${TWO_HOURS_IN_MILLISECONDS}`
  },
  {
    id: 'live',
    title: 'LIVE Visitors',
    value: rows.filter((item) => item.appointmentType === AppointmentTypes.LIVE)
      .length,
    filterKey: 'appointmentType',
    filterValue: AppointmentTypes.LIVE
  },
  {
    id: 'mismatch',
    title: 'Mismatches',
    value: rows.filter((item) => item.mismatch).length,
    filterKey: 'mismatch',
    filterValue: 'yes'
  }
]

export default prepareDriversStats
