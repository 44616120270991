import { FC } from 'react'

import { Button, Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

interface IProps {
  onClick: () => void
}

const NoInvites: FC<IProps> = (props) => {
  const { onClick } = props

  return (
    <Col
      items="center"
      justify="center"
      gap={16}
      className="tw-flex-1 tw-max-w-[410px] tw-mx-auto"
    >
      <Text type={TextTypes.TEXT_LG} color={Color.gray700}>
        There are currently no pending invites.
      </Text>

      <Text type={TextTypes.TEXT_MD} color={Color.gray600}>
        When you invite a user, they’ll appear here until they accept your
        invite. You can also cancel pending invites.
      </Text>

      <Button type="outlined" onClick={onClick}>
        Invite User
      </Button>
    </Col>
  )
}

export default NoInvites
