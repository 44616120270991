import clsx from 'clsx'
import React, { FC, MouseEvent, PropsWithChildren, ReactNode } from 'react'

import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'

import styles from './Text.module.scss'

export interface ITextProps {
  type?: TextTypes
  weight?: FontWeight
  align?: TextAlign
  className?: string
  color?: string | string[]
  children?: ReactNode
  onClick?: (e: MouseEvent<HTMLParagraphElement>) => void
}

const Text: FC<PropsWithChildren<ITextProps>> = ({
  color,
  onClick,
  children,
  className,
  type = TextTypes.TEXT_MD,
  align = TextAlign.LEFT,
  weight = FontWeight.REGULAR
}: ITextProps) => {
  const baseClasses = [
    weight,
    `text-${align}`,
    styles[type],
    styles.inlineBlock,
    className
  ]

  if (color && Array.isArray(color)) {
    baseClasses.unshift(...color)
  } else if (color) {
    baseClasses.unshift(color)
  }

  return (
    <p onClick={onClick} className={clsx(baseClasses)}>
      {children}
    </p>
  )
}

export default Text
