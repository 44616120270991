import { FC, PropsWithChildren } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Portal, Row } from '@/components/atoms'
import { BREAKPOINTS } from '@/constants'

import styles from './HeaderActionPortal.module.scss'

const HeaderActionPortal: FC<PropsWithChildren> = (props) => {
  const { children } = props

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.MD

  if (isSmallView) {
    return (
      <Row gap={10} items="center" className={styles.actionWrapper}>
        {children}
      </Row>
    )
  }

  return <Portal wrapperId="header-actions">{children}</Portal>
}

export default HeaderActionPortal
