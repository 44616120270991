import { faker } from '@faker-js/faker'

import { TRUCK_IMAGES } from '@/constants'
import { IISRItem } from '@/features/isr/types'
import {
  accountsList,
  classesList,
  emissionTypeByFuelMapper,
  fuelTypesList
} from '@/utils/mappers'

const generateMockData = (): IISRItem => {
  const checkInTime = faker.date.recent()
  const checkOutTime = faker.date.between(checkInTime, new Date())

  const puFuelType = faker.helpers.arrayElement(fuelTypesList)
  const emissionType = emissionTypeByFuelMapper[puFuelType]

  return {
    id: faker.datatype.uuid(),
    checkInImg: TRUCK_IMAGES[0],
    checkOutImg: TRUCK_IMAGES[0],

    checkInTime,
    checkOutTime,

    puName: faker.random.alphaNumeric(9).toUpperCase(),
    puLPN: faker.vehicle.vrm(),
    puLPNState: faker.address.stateAbbr(),
    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    puVIN: faker.vehicle.vin(),
    puClass: faker.helpers.arrayElement(classesList),
    puFuelType,
    emissionType,
    account: [faker.helpers.arrayElement(accountsList)],

    driverName: `${faker.name.firstName()} ${faker.name.lastName()}`,
    driverLicenseNum: faker.random.alphaNumeric(9).toUpperCase(),

    checkInAssetName: faker.random.alphaNumeric(9).toUpperCase(),
    checkInAssetLPN: faker.random.alphaNumeric(6).toUpperCase(),
    checkInAssetLPNState: faker.address.stateAbbr(),
    checkInShipmentNumber: faker.random.alphaNumeric(9).toUpperCase(),
    checkInSealNumber: faker.random.alphaNumeric(9).toUpperCase(),
    checkInSealMatchPW: faker.datatype.boolean(),

    checkOutAssetName: faker.random.alphaNumeric(9).toUpperCase(),
    checkOutAssetLPN: faker.random.alphaNumeric(6).toUpperCase(),
    checkOutAssetLPNState: faker.address.stateAbbr(),
    checkOutShipmentNumber: faker.random.alphaNumeric(9).toUpperCase(),
    checkOutSealNumber: faker.random.alphaNumeric(9).toUpperCase(),
    checkOutSealMatchPW: faker.datatype.boolean(),

    source: `${faker.name.firstName()} ${faker.name.lastName()}`,
    mismatch: faker.datatype.boolean()
  }
}

const isrMockData: IISRItem[] = Array.from({ length: 10 }, generateMockData)

export default isrMockData
