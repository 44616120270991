const createWrapperAndAppendToBody = (
  wrapperId: string,
  className?: string
) => {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)

  if (className) {
    wrapperElement.classList.add(className)
  }

  document.body.appendChild(wrapperElement)
  return wrapperElement
}

export default createWrapperAndAppendToBody
