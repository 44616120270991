const ROUTES = {
  HOME: '/',
  GATE_QUEUE: '/gate/queue',
  GATE_DRIVERS: '/gate/drivers',
  GATE_TRANSACTIONS: '/gate/transactions',
  YARD_CARGO_ASSET: '/yard/cargo-asset',
  ISR: '/isr',
  ADMIN_MISMATCHES: '/administration/mismatches',
  ADMIN_VIDEO: '/administration/video',
  USERS: '/users',
  PROFILE: '/profile',
  HELP: '/help'
}

export default ROUTES
