import { faker } from '@faker-js/faker'

import { TRUCK_IMAGES } from '@/constants'
import { IDiscardedDetection } from '@/features/mismatches/types'

const discardedDetectionsMockData: IDiscardedDetection[] = [
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',
    reason: 'Some reason here',
    images: [TRUCK_IMAGES[2]]
  },
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type 1',
    reason: 'Some reason 2',
    images: [TRUCK_IMAGES[2]]
  },
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type 2',
    reason: 'Some reason 2',
    images: [TRUCK_IMAGES[2]]
  },
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',
    reason: 'Some reason here',
    images: [TRUCK_IMAGES[2]]
  }
]

export default discardedDetectionsMockData
