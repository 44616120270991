import React, { FC } from 'react'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

interface IProps {
  clearFilters: () => void
}

const NoResults: FC<IProps> = (props) => {
  const { clearFilters } = props

  return (
    <Col items="center" justify="center" gap={8} className="tw-flex-1">
      <Text type={TextTypes.TEXT_LG} color={Color.gray700}>
        No results found.
      </Text>

      <Text type={TextTypes.TEXT_MD} color={Color.gray600}>
        Try searching or filtering by different criteria.{' '}
        <span
          className="color-green500 tw-cursor-pointer"
          onClick={clearFilters}
        >
          Clear Filters
        </span>
      </Text>
    </Col>
  )
}

export default NoResults
