import { EmissionType, FuelTypes } from '@/types/enums/transactionDetails'

const emissionTypeByFuelMapper: { [key in FuelTypes]: EmissionType } = {
  [FuelTypes.HFC]: EmissionType.NZE,
  [FuelTypes.CNG]: EmissionType.NZE,
  [FuelTypes.LNG]: EmissionType.NZE,
  [FuelTypes.Gas]: EmissionType.E,
  [FuelTypes.Diesel]: EmissionType.E,
  [FuelTypes.Electric]: EmissionType.ZE
}

export default emissionTypeByFuelMapper
