import { faker } from '@faker-js/faker'

import { TRUCK_IMAGES } from '@/constants'
import { MismatchType } from '@/features/mismatches/enums'
import { IMismatch } from '@/features/mismatches/types'

const mismatchesMockData: IMismatch[] = [
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',

    puLPN: '54819E3',
    cvLPN: '54819E3',

    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    cvUsDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),

    type: MismatchType.DOT,
    images: [TRUCK_IMAGES[0], TRUCK_IMAGES[2]]
  },
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',

    puLPN: '123564D',
    cvLPN: '123564D',

    usDOT: faker.datatype.number({ min: 100000000, max: 999999999 }),
    cvUsDOT: undefined,

    type: MismatchType.DOT,
    images: [TRUCK_IMAGES[0], TRUCK_IMAGES[2]]
  },
  {
    id: faker.datatype.uuid(),
    transactionTime: faker.date.recent(),
    transactionType: 'Type',

    puLPN: '123564D',
    cvLPN: '54819E3',

    usDOT: 2352356,
    cvUsDOT: 2352356,

    type: MismatchType.LPN,
    images: [TRUCK_IMAGES[0], TRUCK_IMAGES[2]]
  }
]

export default mismatchesMockData
