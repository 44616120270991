import { Table } from '@/components/organisms'
import {
  manualEntriesMockData,
  manualEntryColumns
} from '@/features/mismatches/utils'

const ManualEntryTable = () => (
  <Table
    clickable
    name="manual-entry"
    idAccessor="id"
    rows={manualEntriesMockData}
    columns={manualEntryColumns}
  />
)

export default ManualEntryTable
