import IosShareIcon from '@mui/icons-material/IosShare'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Col, HeaderActionPortal } from '@/components/atoms'
import { Stats } from '@/components/molecules'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { ISRDetailsModal } from '@/features/isr'
import { IISRItem } from '@/features/isr/types'
import {
  generateIsrStats,
  isrColumns,
  isrFilters,
  isrGroupBy,
  isrGroupingHelper,
  isrHighOrderColumns,
  isrMockData,
  prepareIsrUrlFilters
} from '@/features/isr/utils'
import { useModal } from '@/hooks'
import { TableActions } from '@/types/enums/table'
import { IStatsItem } from '@/types/interfaces/ui'

const ISRTemplate = () => {
  const { visible, openModal, closeModal: close } = useModal(false)

  const [searchParams] = useSearchParams()
  const [selectedItem, setSelectedItem] = useState<IISRItem | undefined>()

  const puClassFilter = searchParams.get('puClass')
  const emissionFilter = searchParams.get('emissionType')
  const searchFields: (keyof IISRItem)[] = [
    'puName',
    'puLPN',
    'usDOT',
    'puVIN',
    'puClass',
    'puFuelType',
    'account',
    'driverName',
    'driverLicenseNum',
    'checkInAssetName',
    'checkInAssetLPN',
    'checkInShipmentNumber',
    'checkInSealNumber',
    'checkOutAssetName',
    'checkOutAssetLPN',
    'checkOutShipmentNumber',
    'checkOutSealNumber',
    'source'
  ]

  const defaultFilters = useMemo(
    () => prepareIsrUrlFilters(puClassFilter, emissionFilter),
    [puClassFilter, emissionFilter]
  )

  const statsItems: IStatsItem[] = useMemo(
    () => generateIsrStats(isrMockData),
    []
  )

  const closeModal = () => {
    close()
    setSelectedItem(undefined)
  }

  const handleAction = (action: TableActions, row: IISRItem) => {
    switch (action) {
      case TableActions.RowClick: {
        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <WithHeaderTemplate title="ISR">
      {visible && (
        <ISRDetailsModal item={selectedItem} closeModal={closeModal} />
      )}

      <Col items="stretch" className="tw-h-full">
        <HeaderActionPortal>
          <Button type="primary" startIcon={<IosShareIcon />}>
            Export
          </Button>
        </HeaderActionPortal>

        <Stats items={statsItems} className="tw-grid-cols-5" />

        <div className="tw-flex-1 tw-flex">
          <Table
            clickable
            name="isr"
            idAccessor="id"
            rows={isrMockData}
            filters={isrFilters}
            columns={isrColumns}
            searchFields={searchFields}
            groupByOptions={isrGroupBy}
            handleAction={handleAction}
            defaultFilters={defaultFilters}
            groupingHelper={isrGroupingHelper}
            highOrderColumns={isrHighOrderColumns}
            searchPlaceholder="Search Transaction"
          />
        </div>
      </Col>
    </WithHeaderTemplate>
  )
}

export default ISRTemplate
