import * as Sentry from '@sentry/react'
import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  ErrorTemplate,
  MainTemplate,
  ProtectedRouteTemplate
} from '@/components/templates'
import config from '@/config'
import {
  GateDriversTemplate,
  GateQueueTemplate,
  GateTransactionTemplate
} from '@/features/gate'
import { HomeTemplate } from '@/features/home'
import { ISRTemplate } from '@/features/isr'
import { MismatchesTemplate } from '@/features/mismatches'
import { ProfileTemplate } from '@/features/profile'
import { UserManagementTemplate } from '@/features/user-management'
import { CargoAssetTemplate } from '@/features/yard'
import ROUTES from '@/router/routes'
import { ErrorType } from '@/types/enums/ui'

const SentryRoutes = !config.isDev
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes

const RootRouter: FC = () => (
  <ProtectedRouteTemplate>
    <SentryRoutes>
      <Route element={<MainTemplate />}>
        <Route path={ROUTES.HOME} element={<HomeTemplate />} />

        <Route
          path={ROUTES.GATE_TRANSACTIONS}
          element={<GateTransactionTemplate />}
        />
        <Route path={ROUTES.GATE_QUEUE} element={<GateQueueTemplate />} />
        <Route path={ROUTES.GATE_DRIVERS} element={<GateDriversTemplate />} />
        <Route path={ROUTES.ISR} element={<ISRTemplate />} />
        <Route path={ROUTES.ADMIN_VIDEO} element={<div>Video</div>} />
        <Route
          path={ROUTES.ADMIN_MISMATCHES}
          element={<MismatchesTemplate />}
        />
        <Route
          path={ROUTES.YARD_CARGO_ASSET}
          element={<CargoAssetTemplate />}
        />
        <Route path={ROUTES.USERS} element={<UserManagementTemplate />} />
        <Route path={ROUTES.PROFILE} element={<ProfileTemplate />} />

        <Route path="*" element={<ErrorTemplate type={ErrorType.NotFound} />} />
      </Route>
    </SentryRoutes>
  </ProtectedRouteTemplate>
)

export default RootRouter
