import AddIcon from '@mui/icons-material/Add'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import React, { useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Button } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { WithHeaderTemplate } from '@/components/templates'
import { BREAKPOINTS } from '@/constants'
import { CheckInModal, CheckOutModal, GateQueue } from '@/features/gate'
import { IGateQueueItem } from '@/features/gate/types'
import { checkInItems, checkOutItems } from '@/features/gate/utils'
import { useModal } from '@/hooks'
import { TransactionDirectionTypes } from '@/types/enums/transactionDetails'
import { ITab } from '@/types/interfaces/ui'

import styles from './GateQueueTemplate.module.scss'

const GateQueueTemplate = () => {
  const { visible, openModal, closeModal } =
    useModal<TransactionDirectionTypes>()
  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.LG

  const [activeTab, setActiveTab] = useState<TransactionDirectionTypes>(
    TransactionDirectionTypes.CheckIn
  )
  const [items, setItems] = useState({
    checkInItems,
    checkOutItems
  })

  const onDelete = (type: TransactionDirectionTypes, id: string) => {
    const collectionId =
      type === TransactionDirectionTypes.CheckIn
        ? 'checkInItems'
        : 'checkOutItems'

    setItems((prev) => ({
      ...prev,
      [collectionId]: prev[collectionId].filter((item) => item.id !== id)
    }))
  }

  const onSwap = (type: TransactionDirectionTypes, id: string) => {
    const isCheckIn = type === TransactionDirectionTypes.CheckIn
    const collectionId = isCheckIn ? 'checkInItems' : 'checkOutItems'

    const item = items[collectionId].find((i) => i.id === id) as IGateQueueItem

    setItems((prev) => ({
      checkOutItems: isCheckIn
        ? [item, ...prev.checkOutItems]
        : prev.checkOutItems.filter((i) => i.id !== id),

      checkInItems: isCheckIn
        ? prev.checkInItems.filter((i) => i.id !== id)
        : [item, ...prev.checkInItems]
    }))
  }

  const CheckInQueue = (
    <GateQueue
      tabsMode={isSmallView}
      onDelete={onDelete}
      onSwap={onSwap}
      items={items.checkInItems}
      type={TransactionDirectionTypes.CheckIn}
    />
  )

  const CheckOutQueue = (
    <GateQueue
      tabsMode={isSmallView}
      onDelete={onDelete}
      onSwap={onSwap}
      items={items.checkOutItems}
      type={TransactionDirectionTypes.CheckOut}
    />
  )

  const tabs: ITab[] = [
    {
      id: TransactionDirectionTypes.CheckIn,
      title: 'Check-In',
      icon: <LoginOutlinedIcon />,
      items: items.checkInItems.length,
      Component: CheckInQueue
    },
    {
      id: TransactionDirectionTypes.CheckOut,
      icon: <LogoutOutlinedIcon />,
      title: 'Check-Out',
      items: items.checkOutItems.length,
      Component: CheckOutQueue
    }
  ]

  return (
    <WithHeaderTemplate title="Gate Queue">
      {visible === TransactionDirectionTypes.CheckIn && (
        <CheckInModal closeModal={closeModal} />
      )}

      {visible === TransactionDirectionTypes.CheckOut && (
        <CheckOutModal closeModal={closeModal} />
      )}

      {isSmallView ? (
        <Tabs
          countAsBadge
          active={activeTab}
          setActive={setActiveTab}
          tabs={tabs}
          actions={
            <Button type="outlined" onClick={() => openModal(activeTab)}>
              <AddIcon />
            </Button>
          }
        />
      ) : (
        <div className={styles.queues}>
          {CheckInQueue}
          {CheckOutQueue}
        </div>
      )}
    </WithHeaderTemplate>
  )
}

export default GateQueueTemplate
