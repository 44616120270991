import {
  accountsList,
  cargoAssetTypesList,
  carriersList,
  classesList,
  fuelTypesList,
  loadStatusesList,
  powerUnitTypesList
} from '@/utils/mappers/enumsConvertedToArrays'

export const classListSelectOptions = classesList.map((classItem) => ({
  id: classItem,
  label: classItem
}))

export const fuelTypeSelectOptions = fuelTypesList.map((fuelType) => ({
  id: fuelType,
  label: fuelType
}))

export const carriersSelectOptions = carriersList.map((carrier) => ({
  id: carrier,
  label: carrier
}))

export const accountsSelectOptions = accountsList.map((account) => ({
  id: account,
  label: account
}))

export const loadStatusSelectOptions = loadStatusesList.map((status) => ({
  id: status,
  label: status
}))

export const cargoAssetTypeSelectOptions = cargoAssetTypesList.map((asset) => ({
  id: asset,
  label: asset
}))

export const powerUnitTypeSelectOptions = powerUnitTypesList.map((type) => ({
  id: type,
  label: type
}))
