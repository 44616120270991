import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FC } from 'react'

import { Button, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

interface IProps extends IModalWithCloseFn {
  onDelete: () => void
  name: string
  loading: boolean
}

const DeleteUserModal: FC<IProps> = (props) => {
  const { closeModal, onDelete, name, loading } = props

  return (
    <Modal
      size="md"
      title="Delete User"
      closeModal={closeModal}
      cancelButtonText="Cancel"
      footer={
        <Button
          type="danger"
          startIcon={<DeleteOutlineIcon />}
          onClick={onDelete}
          disabled={loading}
        >
          {loading ? 'Deleting...' : 'Delete User'}
        </Button>
      }
    >
      <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
        Are you sure you want to delete <b>{name}</b>? <br />
        <br />
        Deleting this user will remove them from your organization, revoking
        their access. They cannot be restored, but you can re-invite them if
        needed.
      </Text>
    </Modal>
  )
}

export default DeleteUserModal
