import dayjs from 'dayjs'
import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import {
  AppointmentTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible,
  isUsDOTVisible
} from '@/features/gate/utils'

const CheckInSchema = yup.object({
  date: yup
    .object()
    .test('dayjs', 'Date is required', (value) => {
      if (!value) {
        return true
      }
      return dayjs.isDayjs(value)
    })
    .required('Date is required'),
  time: yup
    .object()
    .test('dayjs', 'Date is required', (value) => {
      if (!value) {
        return true
      }
      return dayjs.isDayjs(value)
    })
    .required('Time is required'),
  appointmentType: yup.string().required('Appointment Type is required'),

  puName: yup.string().max(50, 'Max length is 50').required('ID is required'),
  puType: yup.string().required('Type is required'),
  puLPN: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  puLPNState: yup.string().required('State is required'),
  operatingCarrier: yup.string().required('Carrier is required'),

  usDOT: yup.number().when('appointmentType', {
    is: isUsDOTVisible,
    then: (schema) =>
      schema
        .test(
          'len',
          'Min length is 3',
          (val) => !!val && val?.toString()?.length >= 3
        )
        .test(
          'len',
          'Max length is 20',
          (val) => !!val && val?.toString()?.length <= 20
        )
        .required('US DOT # is required')
        .typeError('US DOT # must be a number'),
    otherwise: (schema) => schema
  }),
  puVIN: yup.string().max(20, 'Max length is 20'),
  puClass: yup.string().required('Class is required'),
  puFuelType: yup.string().required('Fuel Type is required'),
  account: yup.array(),

  driverName: yup
    .string()
    .max(50, 'Max length is 50')
    .required('Driver Name is required'),
  driverLicenseNum: yup
    .string()
    .max(20, 'Max length is 20')
    .required('Driver License is required'),
  driverPhoneNum: yup
    .string()
    .test(
      'format',
      'Not valid phone number',
      (val) =>
        !!val &&
        matchIsValidTel(val, {
          onlyCountries: ['US', 'MX', 'CA']
        })
    )
    .notRequired(),

  assetType: yup.string().when(['appointmentType', 'puType'], {
    is: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType) &&
      !isCargoAssetMinimized(puType),
    then: (schema) => schema.required('Asset type is required'),
    otherwise: (schema) => schema
  }),
  assetName: yup.string().when(['appointmentType', 'puType'], {
    is: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType) &&
      !isCargoAssetMinimized(puType),
    then: (schema) =>
      schema.max(50, 'Max length is 50').required('ID is required'),
    otherwise: (schema) => schema
  }),
  assetCarrier: yup.string().when(['appointmentType', 'puType'], {
    is: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType) &&
      !isCargoAssetMinimized(puType),
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  chassisName: yup.string().when(['puType', 'assetType'], {
    is: isChassisIdVisible,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  assetLPN: yup
    .string()
    .trim()
    .when('puType', {
      is: (puType: PowerUnitTypes) => !isCargoAssetMinimized(puType),
      then: (schema) => schema.max(20, 'Max length is 20'),
      otherwise: (schema) => schema
    }),
  assetLPNState: yup.string(),
  loadStatus: yup.string(),
  shipmentNumber: yup.string().when('loadStatus', {
    is: (loadStatus: LoadTypes) => !isAssetEmpty(loadStatus),
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  sealNumber: yup.string().when('loadStatus', {
    is: isAssetEmpty,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  sealMatchesPW: yup.boolean().notRequired()
})

export default CheckInSchema
