import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { FC } from 'react'

import styles from './CollapseArrow.module.scss'

interface IProps {
  visible: boolean
  active: boolean
}

const CollapseArrow: FC<IProps> = (props) => {
  const { visible, active } = props

  if (!visible) return null

  return (
    <div tabIndex={0} className={styles.arrowWrapper}>
      {active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </div>
  )
}

export default CollapseArrow
