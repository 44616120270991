import { TableFilterType } from '@/types/enums/table'
import { IFilterItem } from '@/types/interfaces/table'
import {
  accountsList,
  appointmentTypesList,
  carriersList,
  classesList,
  emissionNameMapper,
  emissionsList,
  eventTypesList,
  fuelTypesList,
  loadStatusesList,
  transactionDirectionsList
} from '@/utils/mappers'

const transactionsFilters: IFilterItem[] = [
  {
    id: 'transactionTime',
    name: 'Date Range',
    type: TableFilterType.DateRange
  },
  {
    id: 'direction',
    name: 'Direction',
    type: TableFilterType.List,
    placeholder: 'Search Direction',
    options: transactionDirectionsList.map((item) => ({
      id: item,
      title: item
    }))
  },
  {
    id: 'eventType',
    name: 'Event Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: eventTypesList.map((item) => ({
      id: item,
      title: item
    }))
  },
  {
    id: 'appointmentType',
    name: 'Appointment Type',
    type: TableFilterType.List,
    placeholder: 'Search Type',
    options: appointmentTypesList.map((item) => ({
      id: item,
      title: item
    }))
  },
  {
    id: 'operatingCarrier',
    name: 'Operating Carrier',
    type: TableFilterType.List,
    placeholder: 'Search Carrier',
    options: carriersList.map((item) => ({
      id: item,
      title: item
    }))
  },
  {
    id: 'puClass',
    name: 'Class',
    type: TableFilterType.List,
    placeholder: 'Search Class',
    options: classesList.map((classItem) => ({
      id: classItem,
      title: classItem
    }))
  },
  {
    id: 'puFuelType',
    name: 'Fuel Type',
    type: TableFilterType.List,
    placeholder: 'Search Fuel Type',
    options: fuelTypesList.map((fuel) => ({
      id: fuel,
      title: fuel
    }))
  },
  {
    id: 'emissionType',
    name: 'Fuel Emission Type',
    type: TableFilterType.List,
    placeholder: 'Search Emission Type',
    options: emissionsList.map((emission) => ({
      id: emission,
      title: emissionNameMapper[emission]
    }))
  },
  // asset owner
  {
    id: 'loadStatus',
    name: 'Load Status',
    type: TableFilterType.List,
    placeholder: 'Search Status',
    options: loadStatusesList.map((status) => ({
      id: status,
      title: status
    }))
  },
  {
    id: 'account',
    name: 'Account',
    type: TableFilterType.List,
    placeholder: 'Search Account',
    options: accountsList.map((account) => ({
      id: account,
      title: account
    }))
  },
  {
    id: 'mismatch',
    name: 'Mismatches',
    type: TableFilterType.List,
    placeholder: 'Search Value',
    options: [
      { id: 'yes', title: 'Yes' },
      { id: 'no', title: 'No' }
    ],
    valuesMapper: {
      yes: true,
      no: false
    }
  }
]

export default transactionsFilters
