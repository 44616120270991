import {
  Accounts,
  AppointmentTypes,
  CargoAssetTypes,
  Carriers,
  Classes,
  EmissionType,
  EventTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  TransactionDirectionTypes
} from '@/types/enums/transactionDetails'

export const fuelTypesList: FuelTypes[] = Object.values(FuelTypes)

export const classesList: Classes[] = Object.values(Classes)

export const emissionsList: EmissionType[] = Object.values(EmissionType)
export const accountsList: Accounts[] = Object.values(Accounts)

export const carriersList: Carriers[] = Object.values(Carriers)

export const transactionDirectionsList: TransactionDirectionTypes[] =
  Object.values(TransactionDirectionTypes)

export const eventTypesList: EventTypes[] = Object.values(EventTypes)

export const loadStatusesList: LoadTypes[] = Object.values(LoadTypes)

export const appointmentTypesList: AppointmentTypes[] =
  Object.values(AppointmentTypes)

export const cargoAssetTypesList: CargoAssetTypes[] =
  Object.values(CargoAssetTypes)

export const powerUnitTypesList: PowerUnitTypes[] =
  Object.values(PowerUnitTypes)
