import React from 'react'

import { ErrorTemplate, WithHeaderTemplate } from '@/components/templates'
import { useStore } from '@/store'

const HomeTemplate = () => {
  const { selectedPortal } = useStore((store) => store.user)

  return (
    <WithHeaderTemplate title="Home">
      <ErrorTemplate
        title={selectedPortal ? 'No roles assigned' : 'No portals available'}
        description={
          selectedPortal
            ? "You have access to the selected portal, but no roles have been assigned. Please contact your organization's administrator for assistance."
            : "You don't have permission to access any portal. Please contact your organization's administrator for assistance."
        }
      />
    </WithHeaderTemplate>
  )
}

export default HomeTemplate
