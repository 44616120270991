import { Table, TableBody, TableContainer } from '@mui/material'
import clsx from 'clsx'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

import { Col, Footer, Loader } from '@/components/atoms'
import { TableContextProvider } from '@/components/contexts'
import { TableActions } from '@/types/enums/table'
import {
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  IHighOrderColumn,
  ITableColumn
} from '@/types/interfaces/table'
import { Order } from '@/types/interfaces/ui'
import { filter, search, sort } from '@/utils/table'

import {
  NoResults,
  TableFilters,
  TableHeader,
  TablePagination,
  TableRow as CustomTableRow
} from './components'
import styles from './Table.module.scss'

interface IProps {
  name: string
  loading?: boolean
  columns: (groupBy: string | undefined) => ITableColumn[]
  highOrderColumns?: (groupBy: string | undefined) => IHighOrderColumn[]
  rows: any[]
  idAccessor: string
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  onSearch?: (value: string) => void
  perPage?: number
  currentPage?: number
  totalPages?: number
  totalItems?: number
  onPageChange?: (page: number) => void
  searchPlaceholder?: string
  searchFields?: string[]
  groupByOptions?: IGroupByItem[]
  EmptyScreen?: ReactNode
  isRowHighlighted?: (row: any) => boolean
  isRowDisabled?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  groupingHelper?: (groupBy: string, rows: any[]) => any[]
}

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    columns,
    rows = [],
    idAccessor,
    onSearch,
    onPageChange,
    loading = false,
    currentPage = 0,
    totalPages = 1,
    totalItems = 0,
    perPage = 25,
    clickable,
    filters,
    searchFields,
    groupByOptions,
    defaultFilters,
    handleAction,
    isRowHighlighted,
    isRowDisabled,
    groupingHelper,
    highOrderColumns,
    searchPlaceholder = 'Search',
    EmptyScreen
  } = props

  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})

  const [orderBy, setOrderBy] = useState<string | undefined>()
  const [order, setOrder] = useState<Order>(undefined)

  const debouncedSearch = useDebounce(searchValue, 500)

  const withSearch = !!searchFields || !!onSearch
  const withFilters = withSearch || !!groupByOptions || !!filters

  const memoCols: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])
  const memoHighOrderCols: IHighOrderColumn[] | undefined = useMemo(
    () => highOrderColumns?.(groupBy),
    [groupBy]
  )

  const memoRows: any[] = useMemo(() => {
    const withSearchApplied = search(searchFields, searchValue, rows)

    const filtered = filters?.length
      ? filter(filters, filterValues, withSearchApplied)
      : withSearchApplied

    const grouped = groupBy
      ? groupingHelper?.(groupBy, filtered) || []
      : filtered

    if (orderBy && order) {
      return sort({ rows: grouped, columns: memoCols, order, orderBy, groupBy })
    }

    return grouped
  }, [rows, groupBy, searchValue, filterValues, orderBy, order])

  const withItems = !!memoRows.length
  const noItems = !memoRows.length && !rows.length
  const noItemsAndNoEmptyScreen = noItems && !EmptyScreen
  const noItemsAfterFilterApplied = !memoRows.length && rows.length

  const providerValue = useMemo(
    () => ({
      searchValue,
      filterValues,
      groupBy,
      searchPlaceholder,

      filters,
      groupByOptions,

      isRowHighlighted,
      isRowDisabled,
      setFilterValues,
      handleAction,
      onSearchChange: setSearchValue,
      onGroupByChange: setGroupBy
    }),
    [
      searchValue,
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      handleAction,
      isRowHighlighted,
      isRowDisabled,
      searchPlaceholder
    ]
  )

  const clearFilters = () => {
    setSearchValue('')
    setFilterValues({})
  }

  const handleSort = (property: string) => {
    if (orderBy !== property) {
      setOrderBy(property)
      setOrder('asc')
    } else if (order === 'asc') {
      setOrder('desc')
    } else {
      setOrderBy(undefined)
      setOrder(undefined)
    }
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    // Trigger search when search value is empty or min 3 characters
    if (!debouncedSearch || debouncedSearch?.length >= 3) {
      onSearch?.(debouncedSearch || '')
      onPageChange?.(0)
    }
  }, [debouncedSearch])

  return (
    <TableContextProvider value={providerValue}>
      <Col items="stretch" className="tw-self-stretch tw-w-full">
        {withFilters && <TableFilters withSearch={withSearch} />}

        <div
          className={clsx(
            styles.tableWrapper,
            withItems && !loading && styles.withItems
          )}
        >
          <TableContainer className={styles.tableContainer}>
            <Table
              size="medium"
              aria-labelledby={name}
              classes={{
                root: clsx(
                  styles.table,
                  withFilters && styles.borderTop,
                  clickable && styles.clickable
                )
              }}
            >
              <TableHeader
                order={order}
                orderBy={orderBy}
                cols={memoCols}
                highOrderCols={memoHighOrderCols}
                handleSort={handleSort}
              />

              {withItems && !loading && (
                <TableBody>
                  {memoRows.map((row) => (
                    <CustomTableRow
                      key={row[idAccessor]}
                      row={row}
                      columns={memoCols}
                      idAccessor={idAccessor}
                      clickable={clickable}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {withItems && !loading && (
            <Footer>
              <TablePagination
                perPage={perPage}
                currentPage={currentPage}
                totalPages={totalPages}
                totalItems={totalItems || memoRows.length}
                onPageChange={onPageChange}
              />
            </Footer>
          )}
        </div>

        {noItems && !loading && EmptyScreen}

        {(noItemsAfterFilterApplied || noItemsAndNoEmptyScreen) && !loading && (
          <NoResults clearFilters={clearFilters} />
        )}

        {loading && (
          <Col items="center" justify="center" gap={8} className="tw-flex-1">
            <Loader />
          </Col>
        )}
      </Col>
    </TableContextProvider>
  )
}

export default CustomTable
