import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip, Col } from '@/components/atoms'
import { FormSection, TwoColumnModalWrapper } from '@/components/molecules'
import { Modal } from '@/components/organisms'
import { VisitDetailsImages } from '@/features/isr'
import { IsrVisitSchema } from '@/features/isr/utils'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

import {
  VisitDetailsCargoAsset,
  VisitDetailsDriverDetails,
  VisitDetailsGeneral,
  VisitDetailsPowerUnit
} from './formSections'
import styles from './ISRDetailsModal.module.scss'

interface IProps extends IModalWithCloseFn {
  item: any
}

const ISRDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props
  const { mismatch, checkInImg, checkOutImg } = item

  const formState = useForm({
    resolver: yupResolver(IsrVisitSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: item
  })

  return (
    <>
      <Modal
        placement="fullScreen"
        title="Visit Details"
        closeModal={closeModal}
        bodyClassName={styles.modal}
        headerClassName="!tw-bg-transparent"
        header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
        footer={<Button type="primary">Save Changes</Button>}
      >
        <TwoColumnModalWrapper
          leftSide={
            <VisitDetailsImages
              checkInImg={checkInImg}
              checkOutImg={checkOutImg}
            />
          }
        >
          <FormProvider {...formState}>
            <form>
              <FormSection title="General">
                <VisitDetailsGeneral item={item} />
              </FormSection>

              <FormSection title="Power Unit">
                <VisitDetailsPowerUnit />
              </FormSection>

              <FormSection title="Driver Details">
                <VisitDetailsDriverDetails />
              </FormSection>

              <FormSection title="Cargo Asset">
                <Col items="stretch" gap={40}>
                  <VisitDetailsCargoAsset type="checkIn" />
                  <VisitDetailsCargoAsset type="checkOut" />
                </Col>
              </FormSection>
            </form>
          </FormProvider>
        </TwoColumnModalWrapper>
      </Modal>
    </>
  )
}

export default ISRDetailsModal
