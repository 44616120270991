import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import { FC } from 'react'

import { Avatar, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './PortalDropdownItem.module.scss'

interface IProps {
  type: PortalTypes
  text: string
}

const PortalDropdownItem: FC<IProps> = (props) => {
  const { type, text } = props

  return (
    <Row items="center" gap={4} className={styles.itemWrapper}>
      {type === PortalTypes.Enterprise ? (
        <BusinessOutlinedIcon className={styles.enterpriseSvg} />
      ) : (
        <Avatar
          color={Color.blue300}
          textColor={Color.gray0}
          name={text}
          size="md"
        />
      )}

      <Text
        type={TextTypes.TEXT_SM}
        color={Color.gray700}
        weight={FontWeight.SEMIBOLD}
        className="!tw-leading-[16px]"
      >
        {text}
      </Text>
    </Row>
  )
}

export default PortalDropdownItem
