import { FC } from 'react'

import { Switch } from '@/components/atoms'
import { TableActions } from '@/types/enums/table'
import { ITableToggleColumn } from '@/types/interfaces/table'

interface IProps {
  row: any
  column: ITableToggleColumn
  handleAction?: (action: TableActions, row: any) => void
}

const ToggleColumn: FC<IProps> = (props) => {
  const { row, handleAction, column } = props

  const { id, text } = column

  const value = !row[id]
  const label = value ? text[0] : text[1]

  const onToggle = (newValue: boolean) => {
    if (handleAction) {
      handleAction(TableActions.Toggle, { ...row, [id]: newValue })
    }
  }

  return (
    <div className="tw-min-w-[150px]">
      <Switch small value={value} label={label} onChange={onToggle} />
    </div>
  )
}

export default ToggleColumn
