import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined'
import { Collapse } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { Button, Col, Row } from '@/components/atoms'
import { CardRow } from '@/features/gate'
import { IGateQueueItem, IQueueModalType } from '@/features/gate/types'
import { TransactionDirectionTypes } from '@/types/enums/transactionDetails'
import { millisecondsToDuration } from '@/utils/table'

import styles from './QueueCard.module.scss'

interface IProps {
  item: IGateQueueItem
  type: TransactionDirectionTypes
  onSwap: (type: TransactionDirectionTypes, id: string) => void
  openModal: (item: IGateQueueItem, type: IQueueModalType) => void
}

const QueueCard: FC<IProps> = (props) => {
  const { item, type, onSwap, openModal } = props

  const [expanded, setExpanded] = useState(false)
  const [animationInProgress, setAnimationInProgress] = useState(false)

  const isCheckIn = type === TransactionDirectionTypes.CheckIn
  const userFriendlyDuration = millisecondsToDuration(item, 'duration')

  const toggleDetails = () => {
    setExpanded(!expanded)

    if (expanded) {
      setAnimationInProgress(true)
    }
  }

  const ToggleButton = (
    <Button type="text" className="!tw-px-0 tw-w-fit" onClick={toggleDetails}>
      {expanded ? 'Hide Details' : 'Show Details'}
    </Button>
  )

  const Actions = (
    <Row items="stretch" justify="between" gap={16}>
      <Button
        type="secondary"
        startIcon={isCheckIn ? <LoginOutlinedIcon /> : <LogoutOutlinedIcon />}
        onClick={() => openModal(item, type)}
      >
        {isCheckIn ? 'Check-In' : 'Check-Out'}
      </Button>

      <Row items="stretch" gap={8}>
        <Button
          type="outlined"
          className={styles.swap}
          onClick={() => onSwap(type, item.id)}
        >
          <SwapVertOutlinedIcon />
        </Button>

        <Button
          type="outlined"
          className={styles.bin}
          onClick={() => openModal(item, 'delete')}
        >
          <DeleteOutlineOutlinedIcon />
        </Button>
      </Row>
    </Row>
  )

  return (
    <div className={styles.card}>
      <img src={item.img} alt="Truck Img" className={styles.truckImg} />

      <Col items="stretch" className={styles.cardBody}>
        <CardRow
          titleOne="Date"
          titleTwo="Time"
          valueOne={dayjs(item.date).format('MM/DD/YYYY')}
          valueTwo={dayjs(item.date).format('hh:mm A')}
          span={userFriendlyDuration}
        />

        <CardRow
          titleOne="Power Unit LPN"
          titleTwo="Power Unit ID"
          valueOne={item.puLPN}
          valueTwo={item.puName}
        />

        <Collapse
          in={expanded}
          unmountOnExit
          onExited={() => setAnimationInProgress(false)}
          className={clsx(animationInProgress && '!tw-m-0')}
        >
          <CardRow
            titleOne="Class"
            titleTwo="Operating Carrier"
            valueOne={item.puClass}
            valueTwo={item.operatingCarrier}
          />
        </Collapse>

        <CardRow
          titleOne="Cargo Asset ID"
          titleTwo={expanded ? 'Cargo Asset LPN' : undefined}
          valueOne={item.assetName}
          valueTwo={expanded ? item.assetLPN : undefined}
          button={ToggleButton}
        />

        <Collapse
          in={expanded}
          unmountOnExit
          className={clsx(animationInProgress && '!tw-m-0 tw-opacity-0')}
        >
          <CardRow
            titleOne="Chassis ID"
            titleTwo={undefined}
            valueOne={item.chassisName}
            valueTwo={undefined}
            button={ToggleButton}
          />
        </Collapse>

        {Actions}
      </Col>
    </div>
  )
}

export default QueueCard
