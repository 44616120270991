// Get first letter of the username to render in Avatar component for profile
const getInitials = (name: string) => {
  if (!name) return 'UU'

  const words = name.split(' ')

  if (words.length === 1) {
    return words[0].substring(0, 2).toUpperCase()
  }

  return `${words[0][0] + words[1][0]}`.toUpperCase()
}

export default getInitials
