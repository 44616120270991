import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  arrayToString,
  userFriendlyBoolean,
  userFriendlyEmissionType,
  userFriendlyState
} from '@/utils/table'

const groupTitleMapper: { [key: string]: string } = {
  emissionType: 'Emission Type'
}

const isrColumns = (groupBy: string | undefined): ITableColumn[] => [
  ...(groupBy
    ? [
        {
          id: 'title',
          title: groupTitleMapper[groupBy] || 'Group',
          type: TableColumnType.Text,
          className: 'color-green500',
          sortable: true
        }
      ]
    : []),

  // TRANSACTION
  {
    id: 'checkInTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'checkOutTime',
    title: 'Check-Out Time',
    type: TableColumnType.Date,
    sortable: true
  },

  // POWER UNIT
  {
    id: 'puName',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puLPN',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'usDOT',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puVIN',
    title: 'VIN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puClass',
    title: 'Class',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puFuelType',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'emissionType',
    title: 'Emission',
    type: TableColumnType.Text,
    converter: userFriendlyEmissionType,
    sortable: true
  },
  {
    id: 'account',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },

  // DRIVER DETAILS
  {
    id: 'driverName',
    title: 'Name',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'driverLicenseNum',
    title: 'License',
    type: TableColumnType.Text,
    sortable: true
  },

  // CHECK-IN CARGO ASSET
  {
    id: 'checkInAssetName',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkInAssetLPN',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkInAssetLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'checkInShipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkInSealNumber',
    title: 'Seal #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkInSealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  },

  // CHECK-OUT CARGO ASSET
  {
    id: 'checkOutAssetName',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkOutAssetLPN',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkOutAssetLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'checkOutShipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkOutSealNumber',
    title: 'Seal #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'checkOutSealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  },
  {
    id: 'source',
    title: 'Source',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  }
]

export default isrColumns
