import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, Row, Select } from '@/components/atoms'
import { SuggestedPowerUnitID } from '@/features/gate'
import {
  CheckInSchema,
  isUsDOTVisible,
  suggesterPowerUnitNames
} from '@/features/gate/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { statesOptions } from '@/utils/data'
import {
  accountsSelectOptions,
  carriersSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions,
  powerUnitTypeSelectOptions
} from '@/utils/mappers'

const CheckInPowerUnitFormSection = () => {
  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckInSchema>>()

  const [selectedSuggestedId, setSelectedSuggestedId] = useState<
    string | undefined
  >()

  const powerUnitId = watch('puName')
  const powerUnitType = watch('puType')
  const carrier = watch('operatingCarrier')
  const lpnState = watch('puLPNState')
  const puClass = watch('puClass')
  const fuelType = watch('puFuelType')
  const account = watch('account')
  const appointmentType = watch('appointmentType')

  const showUSDOT = isUsDOTVisible(appointmentType as AppointmentTypes)

  const onSelectSuggestedItem = (value: string) => {
    setSelectedSuggestedId(value)
    setValue('puName', value, { shouldValidate: true })
  }

  useEffect(() => {
    if (powerUnitId !== selectedSuggestedId) {
      setSelectedSuggestedId(undefined)
    }
  }, [powerUnitId])

  return (
    <Col items="stretch" gap={20}>
      <Select
        required
        label="Type"
        value={powerUnitType}
        error={!!errors.puType}
        helperText={errors.puType?.message}
        options={powerUnitTypeSelectOptions}
        onChange={(value) => setValue('puType', value as string)}
      />

      <Col gap={7}>
        <Controller
          name="puName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Input
              required
              uppercase
              fullWidth
              {...field}
              label="ID"
              error={!!errors.puName}
              helperText={errors.puName?.message}
            />
          )}
        />

        <SuggestedPowerUnitID
          suggestedItems={suggesterPowerUnitNames}
          selectedId={selectedSuggestedId}
          onChange={onSelectSuggestedItem}
        />
      </Col>

      <Row gap={16}>
        {showUSDOT && (
          <Input
            {...register('usDOT')}
            required
            fullWidth
            digitsOnly
            type="number"
            label="US DOT #"
            error={!!errors.usDOT}
            helperText={errors.usDOT?.message}
          />
        )}

        <Select
          required
          label="Carrier"
          value={carrier}
          options={carriersSelectOptions}
          error={!!errors.operatingCarrier}
          helperText={errors.operatingCarrier?.message}
          onChange={(value) => setValue('operatingCarrier', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('puLPN')}
          required
          uppercase
          fullWidth
          label="LPN"
          error={!!errors.puLPN}
          helperText={errors.puLPN?.message}
        />

        <Select
          required
          label="LPN State/Region"
          value={lpnState}
          options={statesOptions}
          error={!!errors.puLPNState}
          helperText={errors.puLPNState?.message}
          onChange={(value) => setValue('puLPNState', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('puVIN')}
          uppercase
          fullWidth
          label="VIN"
          error={!!errors.puVIN}
          helperText={errors.puVIN?.message}
        />

        <Select
          required
          label="Class"
          value={puClass}
          options={classListSelectOptions}
          error={!!errors.puClass}
          helperText={errors.puClass?.message}
          onChange={(value) => setValue('puClass', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Select
          required
          label="Fuel Type"
          value={fuelType}
          options={fuelTypeSelectOptions}
          error={!!errors.puFuelType}
          helperText={errors.puFuelType?.message}
          onChange={(value) => setValue('puFuelType', value as string)}
        />

        <Select
          multiple
          label="Account"
          value={account}
          options={accountsSelectOptions}
          error={!!errors.account}
          helperText={errors.account?.message}
          onChange={(value) => setValue('account', value as string[])}
        />
      </Row>
    </Col>
  )
}

export default CheckInPowerUnitFormSection
