import { ISelectOption } from '@/types/interfaces/ui'

const suggesterPowerUnitNames: ISelectOption[] = [
  {
    id: '32499785291',
    label: '32499785291',
    hint: 'Schneider International'
  },
  {
    id: '32499785292',
    label: '32499785292',
    hint: 'Ryder'
  },
  {
    id: '83214970189',
    label: '83214970189',
    hint: 'Ryder'
  },
  {
    id: '4397032223',
    label: '4397032223',
    hint: 'Ryder'
  }
]

export default suggesterPowerUnitNames
