import { ChangeEvent, FC, useMemo, useState } from 'react'

import { Checkbox, Col, Input, Row } from '@/components/atoms'
import { IFilterList } from '@/types/interfaces/table'

interface IProps {
  filter: IFilterList
  selected: string[] | undefined
  onChange: (values: string[] | undefined) => void
}

const ListFilter: FC<IProps> = (props) => {
  const { filter, selected, onChange } = props

  const [search, setSearch] = useState<string>('')

  const options = useMemo(() => {
    if (!search) return filter.options

    return filter.options.filter((option) =>
      option.title.toLowerCase().includes(search.toLowerCase())
    )
  }, [search, filter.options])

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const handleSelectOption = (id: string) => {
    const newSelected = selected?.includes?.(id)
      ? selected.filter((item) => item !== id)
      : [...(selected || []), id]

    onChange(newSelected?.length ? newSelected : undefined)
  }

  return (
    <Col items="stretch" gap={8}>
      <div className="tw-px-8">
        <Input
          value={search}
          onChange={handleSearchChange}
          placeholder={filter.placeholder}
        />
      </div>

      <div>
        {options.map((option) => (
          <Row key={option.id} className="tw-px-9">
            <Checkbox
              label={option.title}
              value={!!selected?.includes?.(option.id)}
              onChange={() => handleSelectOption(option.id)}
            />
          </Row>
        ))}
      </div>
    </Col>
  )
}

export default ListFilter
