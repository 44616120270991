import { TWENTY_FOUR_HOURS_IN_MILLISECONDS } from '@/constants'
import { ICargoAssetItem } from '@/features/yard/types'
import { Carriers } from '@/types/enums/transactionDetails'
import { IStatsItem } from '@/types/interfaces/ui'

type ICarriersCount = {
  [key in Carriers]?: number
}

const generateCargoAssetStats = (rows: ICargoAssetItem[]): IStatsItem[] => {
  const carrierCounts: ICarriersCount = rows.reduce(
    (acc: ICarriersCount, item: any) => {
      acc[item.assetCarrier as Carriers] =
        (acc[item.assetCarrier as Carriers] || 0) + 1
      return acc
    },
    {}
  )

  const [mostFrequentCarrier, count] = Object.entries(carrierCounts).reduce(
    (maxEntry, currentEntry) =>
      currentEntry[1] > maxEntry[1] ? currentEntry : maxEntry,
    ['', 0]
  )

  return [
    { id: 'total', title: 'Total Cargo Assets', value: rows.length },
    {
      id: 'overTwentyFourHours',
      title: 'Over 24 Hours',
      value: rows.filter(
        (item) => item.assetDwell > TWENTY_FOUR_HOURS_IN_MILLISECONDS
      ).length,
      filterKey: 'assetDwell',
      filterValue: `${TWENTY_FOUR_HOURS_IN_MILLISECONDS}`
    },
    {
      id: 'assetCarrier',
      title: 'Top Carrier',
      value: `${mostFrequentCarrier} (${count})`,
      filterKey: 'assetCarrier',
      filterValue: mostFrequentCarrier
    },
    {
      id: 'mismatch',
      title: 'Mismatches',
      value: rows.filter((item) => item.mismatch).length,
      filterKey: 'mismatch',
      filterValue: 'yes'
    }
  ]
}

export default generateCargoAssetStats
