import { yupResolver } from '@hookform/resolvers/yup'
import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FormHelperText } from '@mui/material'
import { isEqual } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Col,
  Input,
  Loader,
  ReadOnlyFormValue,
  Row,
  Select,
  Switch,
  Text
} from '@/components/atoms'
import classes from '@/components/atoms/Input/classes'
import { Modal } from '@/components/organisms'
import {
  useInviteUserMutation,
  useLazyFetchUserOrgRolesQuery,
  useLazyFetchUserSiteRolesQuery,
  useLazyFetchUserSitesQuery,
  useUpdateOrgRolesMutation,
  useUpdateSiteRolesMutation,
  useUpdateUserDetailsMutation
} from '@/features/user-management/api'
import { ISiteRolesResponseData } from '@/features/user-management/api/types'
import { IUser } from '@/features/user-management/types'
import { EditUserSchema } from '@/features/user-management/utils'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { SiteRoles } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { rolesByPortalList } from '@/utils/data'
import { generateRandomId } from '@/utils/helpers'

import styles from './AddEditUserModal.module.scss'

interface IProps extends IModalWithCloseFn {
  editMode?: boolean
  selectedItem?: IUser
  onInviteCallback: () => void
  onEditCallback: () => void
}

interface ISiteRoleListItem {
  id: string
  site_id: string
  roles: SiteRoles[]
}

const AddEditUserModal: FC<IProps> = (props) => {
  const {
    closeModal,
    editMode,
    selectedItem,
    onEditCallback,
    onInviteCallback
  } = props

  const { org, sites } = useStore((store) => store.user)

  // We need to save all sites roles in 1 global state to map through them when editing user
  const [usersCurrentSiteRoles, setUsersCurrentSiteRoles] = useState<
    ISiteRolesResponseData[]
  >([])

  const [inviteUser, { isLoading: isUserCreating }] = useInviteUserMutation()
  const [updateUserDetails, { isLoading: isSavingDetails }] =
    useUpdateUserDetailsMutation()
  const [updateOrgRoles, { isLoading: isSavingOrgRoles }] =
    useUpdateOrgRolesMutation()
  const [updateSiteRoles, { isLoading: isSavingSiteRoles }] =
    useUpdateSiteRolesMutation()

  const [fetchOrgRoles, { data: orgRoles, isFetching: orgRolesFetching }] =
    useLazyFetchUserOrgRolesQuery()
  const [fetchSites, { isFetching: sitesFetching }] =
    useLazyFetchUserSitesQuery()
  const [fetchSiteRoles, { isFetching: siteRolesFetching }] =
    useLazyFetchUserSiteRolesQuery()

  const fetchingAdditionalData =
    orgRolesFetching || siteRolesFetching || sitesFetching
  const loading =
    isUserCreating || isSavingDetails || isSavingOrgRoles || isSavingSiteRoles

  const {
    watch,
    trigger,
    register,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(EditUserSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      first_name: selectedItem?.first_name,
      last_name: selectedItem?.last_name,
      email: selectedItem?.email,

      enterpriseEnabled: false,
      siteEnabled: false,
      roles: [],
      site_roles: []
    }
  })

  const siteEnabled = !!watch('siteEnabled')
  const enterpriseEnabled = !!watch('enterpriseEnabled')
  const enterpriseRoles = watch('roles') || []
  const siteRoles = watch('site_roles') || []

  const title = editMode ? 'Save User' : 'Send Invite'

  const addSiteItem = () => {
    setValue('site_roles', [
      ...siteRoles,
      {
        id: generateRandomId(),
        site_id: undefined,
        roles: []
      }
    ])
  }

  const togglePortalEnabled = (type: 'enterpriseEnabled' | 'siteEnabled') => {
    setValue(type, !watch(type))

    if (type === 'siteEnabled' && !siteEnabled && !siteRoles.length) {
      addSiteItem()
    }

    trigger('isAnyRoleEnabled')
  }

  const updateSite = (
    id: string,
    field: 'site_id' | 'roles',
    value: string | string[] | undefined
  ) => {
    setValue(
      'site_roles',
      siteRoles.map((item) =>
        item.id === id
          ? {
              ...item,
              [field]:
                field === 'site_id' ? (value as string) : (value as string[])
            }
          : item
      )
    )
  }

  const removeSite = (id: string) => {
    setValue(
      'site_roles',
      siteRoles.filter((item) => item.id !== id)
    )

    if (siteRoles.length === 1) {
      setValue('siteEnabled', false)
    }
  }

  const filterOutSiteOptions = (id: string) =>
    sites
      .filter((site) =>
        siteRoles.every(
          (item: ISiteRoleListItem) =>
            item.id === id || item.site_id !== site.id
        )
      )
      .map((site) => ({ id: site.id, label: site.display_name }))

  const onSubmit = async () => {
    const valid = await trigger()

    if (!valid || !org?.organization_id) return

    const values = getValues()

    const { email, first_name, last_name, roles = [], site_roles = [] } = values

    const siteRolesList = site_roles.map((item: ISiteRoleListItem) => ({
      site_id: item.site_id,
      roles: item.roles
    }))

    if (editMode && selectedItem) {
      if (
        first_name !== selectedItem?.first_name ||
        last_name !== selectedItem?.last_name
      ) {
        await updateUserDetails({
          userId: selectedItem.id,
          orgId: org.organization_id,
          first_name,
          last_name,
          display_name: `${first_name} ${last_name}`
        })
      }

      const newRoles = enterpriseEnabled ? roles : []

      if (!isEqual(newRoles, orgRoles?.data?.roles)) {
        await updateOrgRoles({
          userId: selectedItem.id,
          orgId: org.organization_id,
          roles: newRoles
        })
      }

      const sitesToUpdate: ISiteRolesResponseData[] = []

      // Make sure we update all already existing sites for this user
      usersCurrentSiteRoles.forEach((site) => {
        const rolesPerSite =
          siteRolesList.find((s) => s.site_id === site.site_id)?.roles || []

        sitesToUpdate.push({
          site_id: site.site_id,
          roles: siteEnabled ? rolesPerSite : []
        })
      })

      // Also verify if there are any new sites added
      siteRolesList.forEach((site) => {
        const alreadyAdded = sitesToUpdate.find(
          (s) => s.site_id === site.site_id
        )

        if (alreadyAdded?.site_id) return

        sitesToUpdate.push({
          site_id: site.site_id,
          roles: site.roles
        })
      })

      await Promise.all(
        sitesToUpdate.map((site) =>
          updateSiteRoles({
            userId: selectedItem.id,
            orgId: org.organization_id,
            siteId: site.site_id,

            // If site portal is disabled for this user, we need to send reset his roles
            roles: siteEnabled ? site.roles : []
          })
        )
      )

      onEditCallback()
      return
    }

    const response = await inviteUser({
      orgId: org.organization_id,
      invitee: {
        first_name,
        last_name,
        email,
        roles: enterpriseRoles ? roles : [],
        site_roles: siteEnabled ? siteRolesList : []
      }
    })

    if (response?.data?.data?.success) {
      onInviteCallback()
    }
  }

  const getOrgRoles = async () => {
    if (!selectedItem || !org?.organization_id) return

    const response = await fetchOrgRoles({
      orgId: org?.organization_id,
      userId: selectedItem.id
    })

    setValue('roles', response?.data?.data?.roles || [])
    setValue('enterpriseEnabled', !!response?.data?.data?.roles?.length)
  }

  const getSites = async () => {
    if (!selectedItem?.id || !org?.organization_id) return

    const sitesResponse = await fetchSites({
      orgId: org?.organization_id,
      userId: selectedItem?.id
    })

    const userSites = sitesResponse?.data?.data?.sites || []

    if (!userSites.length) {
      setValue('siteEnabled', false)
      return
    }

    const allSitesRoles = await Promise.all(
      userSites.map((site) =>
        fetchSiteRoles({
          orgId: org?.organization_id,
          userId: selectedItem?.id,
          siteId: site.id
        })
      )
    )

    const site_roles = allSitesRoles.reduce(
      (list: ISiteRoleListItem[], item) => {
        if (item?.data?.data?.site_id) {
          list.push({
            id: generateRandomId(),
            site_id: item.data.data.site_id,
            roles: item.data.data.roles
          })
        }

        return list
      },
      []
    )

    setValue('site_roles', site_roles)
    setValue('siteEnabled', !!site_roles?.length)

    setUsersCurrentSiteRoles(
      site_roles.map((item) => ({ site_id: item.site_id, roles: item.roles }))
    )
  }

  useEffect(() => {
    clearErrors('roles')
  }, [enterpriseRoles])

  useEffect(() => {
    clearErrors('site_roles')
  }, [siteRoles])

  useEffect(() => {
    if (selectedItem && org?.organization_id) {
      getOrgRoles()
      getSites()
    }
  }, [org])

  return (
    <Modal
      closeModal={closeModal}
      placement="right"
      className="tw-w-[536px]"
      title={editMode ? 'Edit User' : 'Invite User'}
      cancelButtonText="Cancel"
      footer={
        <Button
          action="submit"
          type="primary"
          onClick={onSubmit}
          disabled={loading || fetchingAdditionalData}
        >
          {loading ? 'Saving...' : title}
        </Button>
      }
    >
      <Col gap={8} items="stretch">
        {!editMode && (
          <Text
            type={TextTypes.TEXT_MD}
            color={Color.gray700}
            className="tw-mb-8"
          >
            Enter the email address of the user you want to invite. Each invite
            is specific to an email address and will expire after <b>7 days</b>.
          </Text>
        )}

        <Row gap={8}>
          <Input
            {...register('first_name')}
            fullWidth
            required
            label="First Name"
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
          />
          <Input
            {...register('last_name')}
            fullWidth
            required
            label="Last Name"
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
          />
        </Row>

        {editMode ? (
          <ReadOnlyFormValue
            required
            biggerGap
            title="Email"
            value={selectedItem?.email}
            className="tw-mt-6"
          />
        ) : (
          <Input
            {...register('email')}
            required
            label="Email"
            type="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        )}
      </Col>

      {fetchingAdditionalData ? (
        <Col
          items="center"
          justify="center"
          className="tw-flex-1 tw-min-h-[200px]"
        >
          <Loader />
        </Col>
      ) : (
        <Col gap={8} items="stretch" className="tw-mt-24">
          <Col>
            <Text
              type={TextTypes.TEXT_LG}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray700}
            >
              Roles
            </Text>

            {!!errors.isAnyRoleEnabled && (
              <Text>
                <FormHelperText
                  error
                  variant="standard"
                  classes={classes.helperTextClasses}
                >
                  {errors.isAnyRoleEnabled?.message}
                </FormHelperText>
              </Text>
            )}
          </Col>

          <Switch
            value={enterpriseEnabled}
            label="Enterprise Portal"
            onChange={() => togglePortalEnabled('enterpriseEnabled')}
          />

          {enterpriseEnabled && (
            <Col className={styles.roleListContainer}>
              <Select
                multiple
                required
                label="Role"
                fullWidth={false}
                value={enterpriseRoles}
                options={rolesByPortalList.enterprise}
                error={!!errors.roles}
                helperText={errors.roles?.message}
                onChange={(value) => setValue('roles', value as string[])}
              />
            </Col>
          )}

          <Switch
            value={siteEnabled}
            label="Site Portal"
            onChange={() => togglePortalEnabled('siteEnabled')}
          />

          {siteEnabled && (
            <>
              <Col className={styles.roleListContainer} gap={8}>
                {siteRoles.map((item, index) => (
                  <Row gap={8} key={item.id}>
                    <Select
                      required
                      label="Site"
                      value={item.site_id}
                      options={filterOutSiteOptions(item.id)}
                      // @ts-ignore
                      error={!!errors?.site_roles?.[`${index}`]?.site_id}
                      helperText={
                        // @ts-ignore
                        errors?.site_roles?.[`${index}`]?.site_id?.message
                      }
                      onChange={(value) =>
                        updateSite(item.id, 'site_id', value)
                      }
                    />

                    <Select
                      multiple
                      required
                      label="Role"
                      value={item.roles}
                      options={rolesByPortalList.site}
                      // @ts-ignore
                      error={!!errors.site_roles?.[`${index}`]?.roles}
                      helperText={
                        // @ts-ignore
                        errors.site_roles?.[`${index}`]?.roles?.message
                      }
                      onChange={(value) => updateSite(item.id, 'roles', value)}
                    />

                    <div
                      tabIndex={0}
                      className={styles.deleteIcon}
                      onClick={() => removeSite(item.id)}
                    >
                      <DeleteOutlineIcon />
                    </div>
                  </Row>
                ))}
              </Col>

              {siteRoles.length < sites.length && (
                <Button
                  type="outlined"
                  startIcon={<AddIcon />}
                  className="tw-self-start"
                  onClick={addSiteItem}
                >
                  Add Site
                </Button>
              )}
            </>
          )}
        </Col>
      )}
    </Modal>
  )
}

export default AddEditUserModal
