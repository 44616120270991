import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Col, Input, ReadOnlyFormValue, Row } from '@/components/atoms'

const DRIVER_FULL_NAME_HELPER = "Enter the driver's first and last name"

interface IProps {
  readOnly?: boolean
}

interface IDriverDetailsFormValues {
  driverName: string
  driverLicenseNum: string
  driverPhoneNum?: string
}

const DriverDetailsFormSection: FC<IProps> = (props) => {
  const { readOnly } = props

  const {
    control,
    watch,
    register,
    formState: { errors }
  } = useFormContext<IDriverDetailsFormValues>()

  const driverName = watch('driverName')
  const driverId = watch('driverLicenseNum')
  const driverPhone = watch('driverPhoneNum')

  if (readOnly) {
    return (
      <Col gap={20}>
        <ReadOnlyFormValue title="Full Name" value={driverName} />

        <Row gap={16}>
          <ReadOnlyFormValue title="Driver ID" value={driverId} />
          <ReadOnlyFormValue title="Driver Phone" value={driverPhone} />
        </Row>
      </Col>
    )
  }

  return (
    <Col gap={20}>
      <Input
        {...register('driverName')}
        required
        fullWidth
        label="Full Name"
        error={!!errors.driverName}
        helperText={errors.driverName?.message || DRIVER_FULL_NAME_HELPER}
      />

      <Row gap={16}>
        <Input
          {...register('driverLicenseNum')}
          required
          uppercase
          fullWidth
          label="Driver ID"
          error={!!errors.driverLicenseNum}
          helperText={errors.driverLicenseNum?.message}
        />

        <Controller
          name="driverPhoneNum"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              fullWidth
              type="phone"
              label="Driver Phone"
              error={!!errors.driverPhoneNum}
              helperText={errors.driverPhoneNum?.message}
            />
          )}
        />
      </Row>
    </Col>
  )
}

export default DriverDetailsFormSection
