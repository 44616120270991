import CloseIcon from '@mui/icons-material/Close'
import { FC, MouseEvent } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TableFilterType } from '@/types/enums/table'
import { TextTypes } from '@/types/enums/ui'
import { IFilterItem, IFilterValues } from '@/types/interfaces/table'
import {
  getDateRangeFilterValue,
  getGroupedListFilterValue,
  getListFilterValue,
  getTimeFilterValue
} from '@/utils/table'

import styles from './FilterValues.module.scss'

interface IProps {
  values: IFilterValues
  filters: IFilterItem[]
  onChange: (filterId: string, value: any) => void
  toggleSpecificFilter: (
    e: MouseEvent<HTMLSpanElement>,
    filter: IFilterItem
  ) => void
}
const FilterValues: FC<IProps> = (props) => {
  const { values, filters, onChange, toggleSpecificFilter } = props

  const [searchParams, setSearchParams] = useSearchParams()

  const renderFilterValue = (key: string, value: string | string[] | any) => {
    const filter = filters.find((item) => item.id === key)

    if (!filter) return null

    let valueTitles = ''

    switch (filter.type) {
      case TableFilterType.List: {
        valueTitles = getListFilterValue(filter, value)
        break
      }

      case TableFilterType.Time: {
        valueTitles = getTimeFilterValue(value)
        break
      }

      case TableFilterType.GroupedList: {
        valueTitles = getGroupedListFilterValue(filter, value)
        break
      }

      case TableFilterType.DateRange: {
        valueTitles = getDateRangeFilterValue(filter, value)
        break
      }

      default: {
        break
      }
    }

    return (
      <>
        {filter.name} is{' '}
        <span onClick={(e) => toggleSpecificFilter(e, filter)}>
          {valueTitles}
        </span>
      </>
    )
  }

  const clearFilter = (e: MouseEvent<HTMLDivElement>, key: string) => {
    e.stopPropagation()
    onChange(key, undefined)

    searchParams.delete(key)
    setSearchParams(searchParams)
  }

  return (
    <Row items="center" gap={8}>
      {Object.entries(values).map(
        ([key, value]) =>
          !!value && (
            <Row items="center" gap={6} key={key} className={styles.filterItem}>
              <Text
                type={TextTypes.TEXT_XS}
                color={Color.gray700}
                className={styles.valueHolder}
              >
                {renderFilterValue(key, value)}
              </Text>

              <div
                className={styles.closeIcon}
                tabIndex={0}
                onClick={(e) => clearFilter(e, key)}
              >
                <CloseIcon />
              </div>
            </Row>
          )
      )}
    </Row>
  )
}

export default FilterValues
