import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'

const manualEntryColumns = (): ITableColumn[] => [
  {
    id: 'transactionType',
    title: 'Transaction Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'transactionTime',
    title: 'Transaction Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'puLPN',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'usDOT',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'userId',
    title: 'User ID',
    type: TableColumnType.Text,
    sortable: true
  }
]

export default manualEntryColumns
