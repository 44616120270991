import { IFilterGroupedList } from '@/types/interfaces/table'

interface IValue {
  [key: string]: string[]
}

const getGroupedListFilterValue = (
  filter: IFilterGroupedList,
  value: IValue
): string => {
  const groupedValues: string[] = []

  Object.entries(value).forEach(([groupKey, groupValues]) => {
    const group = filter.options.find((item) => item.id === groupKey)

    if (!group) return

    const groupValuesNames = group.options
      .filter((option) => groupValues.includes(option.id))
      .map((item) => item.title)
      .join(', ')

    groupedValues.push(`${group.title} (${groupValuesNames})`)
  })

  return groupedValues.join(', ')
}

export default getGroupedListFilterValue
