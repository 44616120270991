import { useAuth0 } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Loader } from '@/components/atoms'
import {
  updatePortalRoles,
  updatePortals,
  updateUserDetails
} from '@/features/auth/store'
import { IJwt } from '@/features/auth/types'
import {
  useLazyFetchUserOrgRolesQuery,
  useLazyFetchUserSiteRolesQuery,
  useLazyFetchUserSitesQuery
} from '@/features/user-management/api'
import { store, useDispatch, useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'

const ProtectedRouteTemplate: FC<PropsWithChildren> = (props) => {
  const { children } = props

  const [searchParams] = useSearchParams()
  const { selectedPortal, me, org } = useStore((s) => s.user)

  const [isFetchingProfileData, setFetchingProfileData] = useState(false)

  const [fetchSites] = useLazyFetchUserSitesQuery()
  const [fetchOrgRoles] = useLazyFetchUserOrgRolesQuery()
  const [fetchSiteRoles] = useLazyFetchUserSiteRolesQuery()

  const dispatch = useDispatch()

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0()

  const login = () => {
    const invitation = searchParams.get('invitation')
    const organization = searchParams.get('organization')

    const appState = {
      returnTo: `${window.location.pathname}${window.location.search}`
    }

    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation
        },
        appState
      })

      return
    }

    loginWithRedirect({ appState })
  }

  const getSiteRoles = async (
    userId: string,
    orgId: string,
    siteId: string
  ) => {
    setFetchingProfileData(() => true)

    try {
      const siteRolesResponse = await fetchSiteRoles({
        userId,
        orgId,
        siteId
      })

      if (!siteRolesResponse?.data?.data?.site_id) {
        throw new Error(
          siteRolesResponse?.data?.errors?.[0]?.message ||
            'Error fetching site roles'
        )
      }

      await dispatch(updatePortalRoles(siteRolesResponse?.data?.data))
    } catch {
      await dispatch(updatePortalRoles({ site_id: siteId, roles: [] }))
    } finally {
      setFetchingProfileData(() => false)
    }
  }

  const getUserInfo = async () => {
    try {
      setFetchingProfileData(() => true)

      const token = await getAccessTokenSilently({})
      const decodedJwt: IJwt = jwtDecode(token)

      await dispatch(updateUserDetails(token))

      const sitesResponse = await fetchSites({
        orgId: decodedJwt.org_id,
        userId: decodedJwt.user_id
      })

      const orgRolesResponse = await fetchOrgRoles({
        orgId: decodedJwt.org_id,
        userId: decodedJwt.user_id
      })

      await dispatch(
        updatePortals({
          sites: sitesResponse?.data?.data?.sites || [],
          orgRoles: orgRolesResponse?.data?.data?.roles || []
        })
      )

      const initialSelectedPortal = await store.getState().user.selectedPortal

      if (
        initialSelectedPortal?.id &&
        initialSelectedPortal.type === PortalTypes.Site
      ) {
        await getSiteRoles(
          decodedJwt.user_id,
          decodedJwt.org_id,
          initialSelectedPortal.id
        )
      }
    } catch {
      await dispatch(
        updatePortals({
          sites: [],
          orgRoles: []
        })
      )
    } finally {
      setFetchingProfileData(() => false)
    }
  }

  useEffect(() => {
    if (isLoading) return

    if (isAuthenticated) {
      getUserInfo()
      return
    }

    login()
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    // No need to fetch roles if no user data in store yet
    if (isFetchingProfileData || !me?.user_id || !org?.organization_id) return

    // No need to fetch if no selected portal, or we already know that no roles for this portal
    if (selectedPortal?.id && selectedPortal?.roles === undefined) {
      getSiteRoles(me.user_id, org.organization_id, selectedPortal.id)
    }
  }, [selectedPortal])

  // If user has access to any portal we need to wait for the roles to be fetched
  if (
    !isAuthenticated ||
    isFetchingProfileData ||
    // Even if user has no access no any portals we set "null" value instead of "undefined"
    selectedPortal === undefined ||
    // Check roles only if user has access to any portal
    (selectedPortal?.id && selectedPortal?.roles === undefined)
  ) {
    return <Loader fullScreen />
  }

  return <>{children}</>
}

export default ProtectedRouteTemplate
