import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  arrayToString,
  millisecondsToDuration,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'

dayjs.extend(duration)

const driversColumns = (): ITableColumn[] => [
  {
    id: 'transactionTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'appointmentType',
    title: 'Appointment Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'assetName',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'chassisName',
    title: 'Chassis ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'assetLPN',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'assetLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'assetCarrier',
    title: 'Asset Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'operatingCarrier',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'assetType',
    title: 'Asset Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'arrivingLoadStatus',
    title: 'Arriving Load Status',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'shipmentNumber',
    title: 'Shipment #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'account',
    title: 'Account',
    type: TableColumnType.Text,
    converter: arrayToString,
    sortable: true
  },
  {
    id: 'sealNumber',
    title: 'Seal #',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'sealMatchPW',
    title: 'Seal Matches',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  },
  {
    id: 'assetDwell',
    title: 'Site Dwell',
    type: TableColumnType.Number,
    converter: millisecondsToDuration,
    sortable: true
  },
  {
    id: 'mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  }
]

export default driversColumns
