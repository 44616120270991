import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CargoDetailsReadOnly,
  DriverDetailsFormSection,
  DriverDetailsPowerUnitFormSection
} from '@/features/gate'
import { IDriverItem } from '@/features/gate/types'
import { DriverDetailsSchema } from '@/features/gate/utils'
import { AppointmentTypes } from '@/types/enums/transactionDetails'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { replaceNullToUndefined } from '@/utils/helpers'

interface IProps extends IModalWithCloseFn {
  item: IDriverItem
}

const DriverDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { img, lane, returnVisit, mismatch, transactionTime, appointmentType } =
    item

  const formReturn = useForm({
    resolver: yupResolver(DriverDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',

    defaultValues: {
      ...replaceNullToUndefined(item),

      date: dayjs(transactionTime || new Date()),
      time: dayjs(transactionTime || new Date()),

      appointmentType: appointmentType || AppointmentTypes.DROP
    }
  })

  const { trigger } = formReturn

  const onSubmit = () => {
    trigger()
  }

  return (
    <Modal
      title="Driver Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button action="submit" onClick={onSubmit} type="primary">
          Save Changes
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            <FormAside img={img} lane={lane} returnVisit={returnVisit} />
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection dateTimeReadOnly />
            </FormSection>

            <FormSection title="Power Unit">
              <DriverDetailsPowerUnitFormSection />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection />
            </FormSection>

            <FormSection title="Cargo Asset">
              <CargoDetailsReadOnly item={item} />
            </FormSection>
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default DriverDetailsModal
