import { FormControlLabel, Switch } from '@mui/material'
import clsx from 'clsx'
import { ChangeEvent, FC } from 'react'

import styles from './Switch.module.scss'

interface IProps {
  value: boolean
  onChange: (value: boolean) => void
  label?: string
  disabled?: boolean
  small?: boolean
}

const CustomSwitch: FC<IProps> = (props) => {
  const { small, value, onChange, label, disabled } = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    onChange(!value)
  }

  const Element = (
    <Switch
      checked={value}
      disabled={disabled}
      onChange={handleChange}
      onClick={(e) => e.stopPropagation()}
      classes={{
        root: clsx(small && styles.small),
        thumb: styles.thumb,
        checked: styles.active,
        track: clsx(styles.track, value && styles.active),
        disabled: styles.disabled
      }}
    />
  )

  return label ? (
    <FormControlLabel
      classes={{ label: clsx(styles.label, disabled && styles.disabled) }}
      control={Element}
      label={label}
    />
  ) : (
    Element
  )
}

export default CustomSwitch
