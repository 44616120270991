import { FC } from 'react'

import { Button, Text } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

interface IProps extends IModalWithCloseFn {
  onDisable: () => void
  name: string
  loading: boolean
}

const DisableUserModal: FC<IProps> = (props) => {
  const { closeModal, onDisable, name, loading } = props

  return (
    <Modal
      size="md"
      title="Disable Access"
      closeModal={closeModal}
      cancelButtonText="Cancel"
      footer={
        <Button type="primary" onClick={onDisable} disabled={loading}>
          {loading ? 'Disabling...' : 'Disable Access'}
        </Button>
      }
    >
      <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
        Are you sure you want to disable access for <b>{name}</b>? <br />
        <br />
        Once disabled, the user will no longer be able to log in or access any
        data. You can enable the user at any time to restore access.
      </Text>
    </Modal>
  )
}

export default DisableUserModal
