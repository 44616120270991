import FilterListIcon from '@mui/icons-material/FilterList'
import clsx from 'clsx'
import React, { FC } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Col, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { IStatsItem } from '@/types/interfaces/ui'

import styles from './Stats.module.scss'

interface IProps {
  items: IStatsItem[]
  className?: string
}

const Stats: FC<IProps> = (props) => {
  const { items, className } = props

  const [, setSearchParams] = useSearchParams()

  const applyFilter = (key: string, value: string) => {
    setSearchParams({ [key]: value })
  }

  return (
    <div
      className={clsx(
        styles.statsContainer,
        className,
        items.length % 2 === 0 && styles.evenCount
      )}
    >
      {items.map(({ id, title, value, filterValue, filterKey }) => (
        <Row
          key={id}
          items="center"
          gap={20}
          justify="between"
          className={clsx(styles.statsItem, filterValue && styles.withFilter)}
          clickable={!!filterValue}
          onClick={() =>
            filterValue && applyFilter(filterKey as string, filterValue)
          }
        >
          <Col gap={4}>
            <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
              {title}
            </Text>

            <Text
              type={TextTypes.DISPLAY_XS}
              color={Color.gray700}
              weight={FontWeight.BOLD}
            >
              {value}
            </Text>
          </Col>

          {filterValue && (
            <FilterListIcon
              width={16}
              height={16}
              className={styles.filterIcon}
            />
          )}
        </Row>
      ))}
    </div>
  )
}

export default Stats
