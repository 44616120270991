import { FC } from 'react'

import { Logo } from '@/components/atoms'

import styles from './Loader.module.scss'

interface IProps {
  fullScreen?: boolean
}

const Loader: FC<IProps> = (props) => {
  const { fullScreen = false } = props

  const Spinner = (
    <div className={styles.loaderWrapper}>
      <Logo color="green" mini size={40} />
    </div>
  )

  return fullScreen ? (
    <div className={styles.fullScreenLoading}>{Spinner}</div>
  ) : (
    Spinner
  )
}

export default Loader
