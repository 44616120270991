import { FC, PropsWithChildren } from 'react'

import { Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './FormSection.module.scss'

interface IProps extends PropsWithChildren {
  title: string
}

const FormSection: FC<IProps> = (props) => {
  const { title, children } = props

  return (
    <div className={styles.formSection}>
      <Text
        type={TextTypes.TEXT_MD}
        weight={FontWeight.SEMIBOLD}
        color={Color.gray700}
      >
        {title}
      </Text>

      {children}
    </div>
  )
}

export default FormSection
