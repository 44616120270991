import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Checkbox, Col, Input, Row, Select } from '@/components/atoms'
import { CargoAssetDetailsSchema } from '@/features/yard/utils'
import { LoadTypes } from '@/types/enums/transactionDetails'
import { statesOptions } from '@/utils/data'
import {
  accountsSelectOptions,
  cargoAssetTypeSelectOptions,
  carriersSelectOptions,
  loadStatusSelectOptions
} from '@/utils/mappers'

const CargoAssetFormSection = () => {
  const {
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CargoAssetDetailsSchema>>()

  const loadStatus = watch('arrivingLoadStatus') as LoadTypes

  const carrier = watch('assetCarrier')
  const sealMatches = !!watch('sealMatchPW')
  const account = watch('account')

  return (
    <Col gap={20}>
      <Select
        required
        label="Asset Type"
        value={watch('assetType')}
        error={!!errors.assetType}
        helperText={errors.assetType?.message}
        options={cargoAssetTypeSelectOptions}
        onChange={(value) => setValue('assetType', value as string)}
      />

      <Row gap={16}>
        <Input
          {...register('assetName')}
          required
          uppercase
          fullWidth
          label="Asset ID"
          error={!!errors.assetName}
          helperText={errors.assetName?.message}
        />

        <Select
          required
          label="Asset Carrier"
          value={carrier}
          options={carriersSelectOptions}
          error={!!errors.assetCarrier}
          helperText={errors.assetCarrier?.message}
          onChange={(value) => setValue('assetCarrier', value as string)}
        />
      </Row>

      <Row gap={16}>
        <Select
          label="Load Status"
          value={loadStatus}
          options={loadStatusSelectOptions}
          error={!!errors.arrivingLoadStatus}
          helperText={errors.arrivingLoadStatus?.message}
          onChange={(value) => setValue('arrivingLoadStatus', value as string)}
        />

        <Input
          {...register('shipmentNumber')}
          uppercase
          fullWidth
          label="Inbound Ref #"
          error={!!errors.shipmentNumber}
          helperText={errors.shipmentNumber?.message}
        />
      </Row>

      <Select
        multiple
        label="Account"
        value={account}
        options={accountsSelectOptions}
        error={!!errors.account}
        helperText={errors.account?.message}
        onChange={(value) => setValue('account', value as string[])}
      />

      <Col items="stretch" gap={4}>
        <Input
          {...register('sealNumber')}
          uppercase
          fullWidth
          label="Seal #"
          error={!!errors.sealNumber}
          helperText={errors.sealNumber?.message}
        />

        <Checkbox
          value={sealMatches}
          label="The seal matches the paperwork "
          onChange={() => setValue('sealMatchPW', !sealMatches)}
        />
      </Col>

      <Input
        {...register('chassisName')}
        uppercase
        fullWidth
        label="Chassis ID"
        error={!!errors.chassisName}
        helperText={errors.chassisName?.message}
      />

      <Row gap={16}>
        <Input
          {...register('assetLPN')}
          uppercase
          fullWidth
          label="LPN"
          error={!!errors.assetLPN}
          helperText={errors.assetLPN?.message}
        />

        <Select
          label="LPN State/Region"
          value={watch('assetLPNState')}
          options={statesOptions}
          error={!!errors.assetLPNState}
          helperText={errors.assetLPNState?.message}
          onChange={(value) => setValue('assetLPNState', value as string)}
        />
      </Row>
    </Col>
  )
}

export default CargoAssetFormSection
