import { useState } from 'react'
// @ts-ignore
import Lightbox from 'react-awesome-lightbox'

import { Table } from '@/components/organisms'
import { IMismatch } from '@/features/mismatches/types'
import {
  discardedDetectionsColumns,
  discardedDetectionsMockData
} from '@/features/mismatches/utils'
import { TableActions } from '@/types/enums/table'

const DiscardedDetectionTable = () => {
  const [imagesToPreview, setImagesToPreview] = useState<string[] | undefined>(
    undefined
  )
  const handleAction = (action: TableActions, row: IMismatch) => {
    switch (action) {
      case TableActions.ColumnAction: {
        setImagesToPreview(row.images)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {!!imagesToPreview && (
        <Lightbox
          images={imagesToPreview}
          onClose={() => setImagesToPreview(undefined)}
        />
      )}

      <Table
        clickable
        name="discarded-detection"
        idAccessor="id"
        rows={discardedDetectionsMockData}
        columns={discardedDetectionsColumns}
        handleAction={handleAction}
      />
    </>
  )
}

export default DiscardedDetectionTable
