import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import React, { FC, KeyboardEvent, MouseEvent, useState } from 'react'

import { Avatar, Row, Text } from '@/components/atoms'
import { CollapsedPortalPicker } from '@/components/organisms/Navigation/components'
import { selectPortal } from '@/features/auth/store'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './PortalsDropdownList.module.scss'

interface IProps {
  collapsed: boolean
}

const PortalsDropdownList: FC<IProps> = (props) => {
  const { collapsed } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const dispatch = useDispatch()
  const { selectedPortal } = useStore((store) => store.user)

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      setAnchorEl(event.currentTarget as HTMLElement)
    }
  }

  const onOpen = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const onClose = () => setAnchorEl(null)

  const onPortalSelect = (id: string) => {
    dispatch(selectPortal(id))
    onClose()
  }

  if (!selectedPortal?.id) return null

  return (
    <>
      <Row
        clickable
        gap={8}
        tabIndex={0}
        items="center"
        className={styles.portalValue}
        onKeyDown={handleKeyPress}
        onClick={onOpen}
      >
        {selectedPortal.type === PortalTypes.Enterprise ? (
          <BusinessOutlinedIcon className={styles.enterpriseSvg} />
        ) : (
          <Avatar
            size="md"
            name={selectedPortal.name}
            color={Color.blue300}
            textColor={Color.gray0}
          />
        )}

        {!collapsed && (
          <Text
            type={TextTypes.TEXT_SM}
            color={Color.gray300}
            weight={FontWeight.SEMIBOLD}
          >
            {selectedPortal.name}
          </Text>
        )}
      </Row>

      <CollapsedPortalPicker
        onClose={onClose}
        anchorEl={anchorEl}
        onItemClick={onPortalSelect}
      />
    </>
  )
}

export default PortalsDropdownList
