import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import { capitalize } from 'lodash'
import { FC, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'usehooks-ts'

import { Col, DarkButton, Logo, Row, Text } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { BREAKPOINTS } from '@/constants'
import { useNavigator } from '@/router'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { getPageTitle } from '@/utils/helpers'

import styles from './NavigationHeader.module.scss'

interface IProps {
  collapsed: boolean
}

const NavigationHeader: FC<IProps> = (props) => {
  const { collapsed } = props

  const location = useLocation()
  const navigate = useNavigator()
  const { toggleMobileNavigation } = useMobileNavigationContext()
  const { width = 0 } = useWindowSize()

  const { org } = useStore((store) => store.user)

  const isSmallView = width <= BREAKPOINTS.MD

  const title = useMemo(
    () => getPageTitle(location.pathname),
    [location.pathname]
  )

  return (
    <Row
      items="center"
      gap={12}
      className={clsx(styles.navHeader, collapsed && styles.collapsed)}
    >
      {isSmallView ? (
        <DarkButton
          id="close-menu"
          className={styles.closeMenuButton}
          onClick={() => toggleMobileNavigation()}
        >
          <CloseIcon />
        </DarkButton>
      ) : (
        <Logo className={styles.logo} onClick={navigate.toHome} />
      )}

      <Col gap={3}>
        <Text
          type={isSmallView ? TextTypes.TEXT_XL : TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray0}
          className={styles.brandName}
        >
          {isSmallView ? title : capitalize(org?.organization_name)}
        </Text>

        {!isSmallView && (
          <Text
            type={TextTypes.TEXT_XS}
            color={Color.gray500}
            className={styles.brandName}
          >
            YardIQ
          </Text>
        )}
      </Col>
    </Row>
  )
}

export default NavigationHeader
