import { TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'
import {
  millisecondsToDuration,
  userFriendlyBoolean,
  userFriendlyState
} from '@/utils/table'

const driversColumns = (): ITableColumn[] => [
  {
    id: 'transactionTime',
    title: 'Check-In Time',
    type: TableColumnType.Date,
    sortable: true
  },
  {
    id: 'appointmentType',
    title: 'Appointment Type',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puName',
    title: 'ID',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'operatingCarrier',
    title: 'Carrier',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'account',
    title: 'Account',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puLPN',
    title: 'LPN',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puLPNState',
    title: 'State',
    type: TableColumnType.Text,
    converter: userFriendlyState,
    sortable: true
  },
  {
    id: 'usDOT',
    title: 'USDOT',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puClass',
    title: 'Class',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puFuelType',
    title: 'Fuel Type',
    type: TableColumnType.Text,
    sortable: true
  },

  {
    id: 'driverName',
    title: 'Driver Name',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'driverLicenseNum',
    title: 'Driver License',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'driverPhoneNum',
    title: 'Driver Phone',
    type: TableColumnType.Phone,
    sortable: true
  },
  {
    id: 'assetName',
    title: 'Arriving Cargo Asset',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'puDwell',
    title: 'Dwell',
    type: TableColumnType.Number,
    converter: millisecondsToDuration,
    sortable: true
  },
  {
    id: 'mismatch',
    title: 'Mismatch',
    type: TableColumnType.Text,
    converter: userFriendlyBoolean,
    sortable: true
  }
]

export default driversColumns
