import { InputAdornment, TextField } from '@mui/material'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  KeyboardEventHandler
} from 'react'

import { TextInputProps } from '@/types/interfaces/ui'

import classes from '../classes'

const TextInput: ForwardRefRenderFunction<HTMLInputElement, TextInputProps> = (
  props,
  ref
) => {
  const {
    icon,
    variant,
    digitsOnly,
    onChange,
    uppercase,
    readOnly,
    ...restProps
  } = props

  const {
    helperTextClasses,
    inputClasses,
    rootClasses,
    inputLabelClasses,
    iconClasses,
    outlinedInputClasses
  } = classes

  const onChangeCustom = (e: ChangeEvent<HTMLInputElement>) => {
    const eventClone = cloneDeep(e)

    if (onChange) {
      if (uppercase) {
        eventClone.target.value = eventClone.target.value?.toUpperCase()
      }

      onChange(eventClone)
    }
  }

  const onKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (evt) => {
    if (digitsOnly && ['e', 'E', '+', '-'].includes(evt.key)) {
      evt.preventDefault()
    }
  }

  return (
    <TextField
      {...restProps}
      variant={variant}
      onChange={onChangeCustom}
      inputRef={ref}
      classes={rootClasses}
      FormHelperTextProps={{ classes: helperTextClasses }}
      InputLabelProps={{ classes: inputLabelClasses }}
      InputProps={{
        readOnly,
        onKeyDown,
        startAdornment: icon && (
          <InputAdornment classes={iconClasses} position="start">
            {icon}
          </InputAdornment>
        ),
        classes: {
          ...inputClasses,
          root:
            variant === 'outlined'
              ? clsx(inputClasses.root, outlinedInputClasses.root)
              : inputClasses.root
        }
      }}
    />
  )
}

export default forwardRef(TextInput)
