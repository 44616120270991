import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { FC } from 'react'

import { Chip, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { ISelectOption } from '@/types/interfaces/ui'

import styles from './SuggestedPowerUnitID.module.scss'

interface IProps {
  suggestedItems: ISelectOption[]
  selectedId: string | undefined
  onChange: (value: string) => void
}

const SuggestedPowerUnitID: FC<IProps> = (props) => {
  const { suggestedItems, selectedId, onChange } = props

  return (
    <Row items="center" gap={24} className={styles.suggestedWrapper}>
      <Row items="center" gap={8}>
        <div className={styles.iconWrapper}>
          <AutoAwesomeOutlinedIcon />
        </div>

        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          Suggested
        </Text>
      </Row>

      <Row items="center" gap={8}>
        {suggestedItems.map((item) => (
          <Chip
            key={item.id}
            label={item.label}
            type={selectedId === item.id ? 'success' : 'default'}
            icon={selectedId === item.id ? <CheckOutlinedIcon /> : undefined}
            onClick={() => onChange(item.id)}
          />
        ))}
      </Row>
    </Row>
  )
}

export default SuggestedPowerUnitID
