import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import ROUTES from '@/router/routes'

class Navigator {
  navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate
  }

  public to = (path: string) => {
    this.navigate(path)
  }

  public toHome = () => {
    this.navigate(ROUTES.HOME)
  }

  public toGateQueue = () => {
    this.navigate(ROUTES.GATE_QUEUE)
  }

  public toGateDrivers = () => {
    this.navigate(ROUTES.GATE_DRIVERS)
  }

  public toGateTransactions = () => {
    this.navigate(ROUTES.GATE_TRANSACTIONS)
  }

  public toCargoAssets = () => {
    this.navigate(ROUTES.YARD_CARGO_ASSET)
  }

  public toISR = () => {
    this.navigate(ROUTES.ISR)
  }

  public toMismatches = () => {
    this.navigate(ROUTES.ADMIN_MISMATCHES)
  }

  public toVideo = () => {
    this.navigate(ROUTES.ADMIN_VIDEO)
  }

  public toUsers = () => {
    this.navigate(ROUTES.USERS)
  }

  public toProfile = () => {
    this.navigate(ROUTES.PROFILE)
  }

  public withParams = (url: string, params?: any) => {
    if (isEmpty(params)) {
      return url
    }

    return `${url}?${queryString.stringify(params)}`
  }

  public goBack = () => {
    this.navigate(-1)
  }
}

export function useNavigator() {
  const navigate = useNavigate()

  return new Navigator(navigate)
}
