export enum Classes {
  Class2b7 = 'Class 2b-7',
  Class8 = 'Class 8'
}

export enum FuelTypes {
  Gas = 'Gas',
  Diesel = 'Diesel',
  CNG = 'CNG',
  LNG = 'LNG',
  HFC = 'HFC',
  Electric = 'Electric'
}

export enum EmissionType {
  E = 'E',
  NZE = 'NZE',
  ZE = 'ZE'
}

export enum Accounts {
  FIGS = 'FIGS',
  OuterStuff = 'OuterStuff',
  SignalNineWest = 'Signal-NineWest',
  SignalGuess = 'Signal-Guess',
  FandT = 'FandT',
  Coolgear = 'Coolgear',
  APL = 'APL',
  KHQ = 'KHQ'
}

export enum Carriers {
  Ryder = 'Ryder',
  NFI = 'NFI'
}

export enum TransactionDirectionTypes {
  CheckIn = 'Check-In',
  CheckOut = 'Check-Out'
}

export enum EventTypes {
  Detection = 'Detection',
  Manual = 'Manual'
}

export enum AppointmentTypes {
  LIVE = 'LIVE',
  Visitor = 'Visitor',
  DROP = 'DROP',
  Bobtail = 'Bobtail'
}

export enum LoadTypes {
  Empty = 'Empty',
  Loaded = 'Loaded',
  Partial = 'Partial'
}

export enum CargoAssetTypes {
  Trailer = 'Trailer',
  OceanContainer = 'Ocean Container',
  IntermodalContainer = 'Intermodal Container'
}

export enum PowerUnitTypes {
  Tractor = 'Tractor',
  BoxTruck = 'Box Truck',
  SprinterVan = 'Sprinter Van',
  Vehicle = 'Vehicle'
}
