export enum PortalTypes {
  Site = 'site-portal',
  Enterprise = 'enterprise'
}

export enum SiteRoles {
  Admin = 'administrator',
  ISRManager = 'isr_manager',
  Gate = 'gate_agent',
  ViewOnly = 'viewer'
}

export enum EnterpriseRoles {
  Admin = 'administrator'
}
