import CloseIcon from '@mui/icons-material/Close'
import { Backdrop, Modal, Slide } from '@mui/material'
import clsx from 'clsx'
import { FC, PropsWithChildren, ReactNode, useState } from 'react'

import { Button, Row, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { IModalWithCloseFn } from '@/types/interfaces/ui'

import styles from './Modal.module.scss'

interface IProps extends IModalWithCloseFn {
  title: string
  formMode?: boolean
  footer?: ReactNode
  header?: ReactNode
  className?: string
  cancelButtonText?: string
  bodyClassName?: string
  headerClassName?: string
  size?: 'sm' | 'md' | 'lg'
  placement?: 'left' | 'right' | 'center' | 'fullScreen'
}

const CustomModal: FC<PropsWithChildren & IProps> = (props) => {
  const {
    formMode,
    closeModal,
    className,
    bodyClassName,
    placement = 'center',
    headerClassName,
    footer,
    header,
    title,
    size = 'sm',
    cancelButtonText,
    children
  } = props

  const [open, setOpen] = useState(true)

  const Wrapper = formMode ? 'form' : 'div'

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Slide
        in={open}
        direction={placement === 'right' ? 'left' : 'up'}
        onExited={closeModal}
      >
        <Wrapper
          className={clsx(
            styles.box,
            styles[placement],
            styles[size],
            className
          )}
        >
          <Row
            items="center"
            gap={10}
            justify="between"
            className={clsx(styles.header, headerClassName)}
          >
            <Text
              weight={FontWeight.SEMIBOLD}
              type={TextTypes.TEXT_XL}
              color={Color.gray700}
            >
              {title}
            </Text>

            <Row items="center" gap={10}>
              {header}
              <div
                className={styles.closeButton}
                tabIndex={0}
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
            </Row>
          </Row>

          <div
            className={clsx(
              'tw-p-16 tw-pb-24 tw-overflow-y-auto',
              bodyClassName
            )}
          >
            {children}
          </div>

          <Row justify="end" items="stretch" gap={8} className={styles.footer}>
            <Button type="outlined" onClick={handleClose}>
              {cancelButtonText || 'Close'}
            </Button>

            {footer}
          </Row>
        </Wrapper>
      </Slide>
    </Modal>
  )
}

export default CustomModal
