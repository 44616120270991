import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Col, Input, ReadOnlyFormValue, Row } from '@/components/atoms'
import { AppointmentTypeSelector } from '@/components/molecules'
import { AppointmentTypes } from '@/types/enums/transactionDetails'

interface IProps {
  dateTimeReadOnly?: boolean
}

interface IGeneralSectionFields {
  date: Dayjs
  time: Dayjs
  appointmentType: AppointmentTypes
}

const GeneralFormSection: FC<IProps> = (props) => {
  const { dateTimeReadOnly } = props

  const {
    watch,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<IGeneralSectionFields>()

  const appointmentType = watch('appointmentType')

  const onAppointmentTypeChange = (value: AppointmentTypes) => {
    setValue('appointmentType', value)
  }

  return (
    <Col gap={20}>
      <Row gap={16}>
        {dateTimeReadOnly ? (
          <ReadOnlyFormValue
            title="Date"
            value={dayjs(watch('date')).format('MM/DD/YYYY')}
          />
        ) : (
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <Input
                required
                fullWidth
                name="date"
                type="date"
                label="Date"
                value={field.value as Dayjs}
                onChange={(date) => {
                  field.onChange(date)
                }}
                error={!!errors.date}
                helperText={errors.date?.message}
              />
            )}
          />
        )}

        {dateTimeReadOnly ? (
          <ReadOnlyFormValue
            title="Time"
            value={dayjs(watch('time')).format('HH:MM')}
          />
        ) : (
          <Controller
            name="time"
            control={control}
            render={({ field }) => (
              <Input
                required
                fullWidth
                name="time"
                type="time"
                label="Time"
                value={field.value as Dayjs}
                onChange={(date) => {
                  field.onChange(date)
                }}
                error={!!errors.time}
                helperText={errors.time?.message}
              />
            )}
          />
        )}
      </Row>

      <AppointmentTypeSelector
        value={appointmentType}
        onChange={onAppointmentTypeChange}
      />
    </Col>
  )
}

export default GeneralFormSection
