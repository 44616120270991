import AutorenewIcon from '@mui/icons-material/Autorenew'
import IosShareIcon from '@mui/icons-material/IosShare'
import { noop } from 'lodash'
import { useMemo, useState } from 'react'

import { Button, Col, HeaderActionPortal } from '@/components/atoms'
import { TableContextProvider } from '@/components/contexts'
import { Tabs } from '@/components/molecules'
import { TableFilters } from '@/components/organisms/Table/components'
import { WithHeaderTemplate } from '@/components/templates'
import {
  DiscardedDetectionTable,
  ManualEntryTable,
  MismatchesCharts,
  MismatchesTable
} from '@/features/mismatches'
import {
  discardedDetectionsMockData,
  manualEntriesMockData,
  mismatchesMockData
} from '@/features/mismatches/utils'
import { IFilterValues } from '@/types/interfaces/table'
import { ITab } from '@/types/interfaces/ui'

import styles from './MismatchesTemplate.module.scss'

const MismatchesTemplate = () => {
  const [filterValues, setFilterValues] = useState<IFilterValues>({})
  const [activeTab, setActiveTab] = useState<
    'mismatches' | 'discarded' | 'manual'
  >('mismatches')

  const tabs: ITab[] = [
    {
      id: 'mismatches',
      title: 'Mismatches',
      items: mismatchesMockData.length,
      Component: <MismatchesTable />
    },
    {
      id: 'discarded',
      title: 'Discarded Detections',
      items: discardedDetectionsMockData.length,
      Component: <DiscardedDetectionTable />
    },
    {
      id: 'manual',
      title: 'Manual Entries',
      items: manualEntriesMockData.length,
      Component: <ManualEntryTable />
    }
  ]

  const providerValue = useMemo(
    () => ({
      filters: [],
      filterValues,
      setFilterValues,

      searchPlaceholder: 'Search',
      groupBy: undefined,
      onSearchChange: noop,
      onGroupByChange: noop
    }),
    [filterValues, setFilterValues]
  )

  return (
    <WithHeaderTemplate title="Mismatches">
      <Col items="stretch" className="tw-h-full">
        <HeaderActionPortal>
          <div tabIndex={0} className={styles.refreshBtn}>
            <AutorenewIcon />
          </div>

          <Button type="primary" startIcon={<IosShareIcon />}>
            Export
          </Button>
        </HeaderActionPortal>

        <div className={styles.borderBottom}>
          <TableContextProvider value={providerValue}>
            <TableFilters withSearch={false} />
          </TableContextProvider>
        </div>

        <MismatchesCharts />

        <Tabs active={activeTab} setActive={setActiveTab} tabs={tabs} />
      </Col>
    </WithHeaderTemplate>
  )
}

export default MismatchesTemplate
