import '@/styles/main.scss'
import 'react-awesome-lightbox/build/style.css'
import 'react-circular-progressbar/dist/styles.css'

import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { ErrorTemplate } from '@/components/templates'
import { initSentry } from '@/services/sentry'
import { store } from '@/store'
import { ErrorType } from '@/types/enums/ui'

import App from './App'

window.global ||= window

initSentry()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorTemplate type={ErrorType.Generic} />}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)
