import { ISelectOption } from '@/types/interfaces/ui'

const suggestedPuLPN: ISelectOption[] = [
  {
    id: 'ME325235A',
    label: 'ME325235A'
  },
  {
    id: 'AF2325ADF',
    label: 'AF2325ADF'
  },
  {
    id: '44AE2355',
    label: '44AE2355'
  }
]

export default suggestedPuLPN
