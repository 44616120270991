import dayjs from 'dayjs'
import * as yup from 'yup'

import {
  AppointmentTypes,
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'

const requireAssetFieldValidation = (
  appointmentType: AppointmentTypes,
  puType: PowerUnitTypes
) =>
  isCargoAssetVisible(appointmentType, puType, false) &&
  !isCargoAssetMinimized(puType)

const CheckOutSchema = yup.object({
  id: yup.string(),
  date: yup
    .object()
    .test('dayjs', 'Date is required', (value) => {
      if (!value) {
        return true
      }
      return dayjs.isDayjs(value)
    })
    .required('Date is required'),
  time: yup
    .object()
    .test('dayjs', 'Date is required', (value) => {
      if (!value) {
        return true
      }
      return dayjs.isDayjs(value)
    })
    .required('Time is required'),
  appointmentType: yup.string().required('Appointment Type is required'),

  puType: yup.string().when('id', {
    is: (id: string) => !!id,
    then: (schema) => schema.required('Type is required'),
    otherwise: (schema) => schema
  }),
  puName: yup.string().required('ID is required'),
  puLPN: yup.string().required('LPN is required'),
  puLPNState: yup.string().when('id', {
    is: (id: string) => !!id,
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema
  }),
  operatingCarrier: yup.string().when('id', {
    is: (id: string) => !!id,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  usDOT: yup.number().when('id', {
    is: (id: string) => !!id,
    then: (schema) =>
      schema
        .test(
          'len',
          'Min length is 3',
          (val) => !!val && val?.toString()?.length >= 3
        )
        .test(
          'len',
          'Max length is 20',
          (val) => !!val && val?.toString()?.length <= 20
        )
        .required('US DOT # is required')
        .typeError('US DOT # must be a number'),
    otherwise: (schema) => schema
  }),
  puVIN: yup.string().when('id', {
    is: (id: string) => !!id,
    then: (schema) => schema.max(20, 'Max length is 20'),
    otherwise: (schema) => schema
  }),
  puClass: yup.string().when('id', {
    is: (id: string) => !!id,
    then: (schema) => schema.required('Class is required'),
    otherwise: (schema) => schema
  }),
  puFuelType: yup.string().when('id', {
    is: (id: string) => !!id,
    then: (schema) => schema.required('Fuel Type is required'),
    otherwise: (schema) => schema
  }),
  account: yup.array(),

  assetType: yup.string().when(['puType', 'appointmentType', 'id'], {
    is: (
      puType: PowerUnitTypes,
      appointmentType: AppointmentTypes,
      id: string
    ) => requireAssetFieldValidation(appointmentType, puType) && !!id,
    then: (schema) => schema.required('Asset type is required'),
    otherwise: (schema) => schema
  }),
  assetName: yup.string().when(['appointmentType', 'puType'], {
    is: requireAssetFieldValidation,
    then: (schema) => schema.required('ID is required'),
    otherwise: (schema) => schema
  }),
  assetCarrier: yup.string().when(['appointmentType', 'puType', 'id'], {
    is: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      id: string
    ) => requireAssetFieldValidation(appointmentType, puType) && !!id,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  assetLPN: yup
    .string()
    .trim()
    .when(['appointmentType', 'puType'], {
      is: requireAssetFieldValidation,
      then: (schema) =>
        schema.max(20, 'Max length is 20').required('LPN is required'),
      otherwise: (schema) => schema
    }),
  loadStatus: yup.string().when(['appointmentType', 'puType'], {
    is: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType, false),
    then: (schema) => schema.required('Load Status is required'),
    otherwise: (schema) => schema
  }),
  shipmentNumber: yup.string().when('loadStatus', {
    is: (loadStatus: LoadTypes) => !isAssetEmpty(loadStatus),
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  chassisName: yup
    .string()
    .when(['appointmentType', 'puType', 'assetType', 'id'], {
      is: (
        appointmentType: AppointmentTypes,
        puType: PowerUnitTypes,
        assetType: CargoAssetTypes,
        id: string
      ) =>
        isCargoAssetVisible(appointmentType, puType, false) &&
        isChassisIdVisible(puType, assetType) &&
        !!id,
      then: (schema) => schema.max(50, 'Max length is 50'),
      otherwise: (schema) => schema
    }),
  sealNumber: yup.string().when('loadStatus', {
    is: isAssetEmpty,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  sealMatchesPW: yup.boolean().notRequired()
})

export default CheckOutSchema
