import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined'

import ROUTES from '@/router/routes'
import { EnterpriseRoles, PortalTypes, SiteRoles } from '@/types/enums/global'
import { INavigationItem } from '@/types/interfaces/ui'

export const navigationItems: INavigationItem[] = [
  {
    id: 'gate',
    title: 'Gate',
    icon: <LocalShippingOutlinedIcon />,
    visible: (
      portalType: PortalTypes,
      roles: SiteRoles[] | EnterpriseRoles[]
    ) =>
      portalType === PortalTypes.Site &&
      roles.some((role) =>
        [SiteRoles.Admin, SiteRoles.Gate].includes(role as SiteRoles)
      ),

    items: [
      {
        id: 'queue',
        title: 'Queue',
        link: ROUTES.GATE_QUEUE
      },
      {
        id: 'transactions',
        title: 'Transactions',
        link: ROUTES.GATE_TRANSACTIONS
      },
      {
        id: 'drivers',
        title: 'Drivers',
        link: ROUTES.GATE_DRIVERS
      }
    ]
  },
  {
    id: 'yard',
    title: 'Yard',
    icon: <ContentPasteOutlinedIcon />,
    visible: (
      portalType: PortalTypes,
      roles: SiteRoles[] | EnterpriseRoles[]
    ) =>
      portalType === PortalTypes.Site &&
      roles.some((role) =>
        [SiteRoles.Admin, SiteRoles.Gate, SiteRoles.ViewOnly].includes(
          role as SiteRoles
        )
      ),
    items: [
      {
        id: 'cargo-asset',
        title: 'Cargo Asset',
        link: ROUTES.YARD_CARGO_ASSET
      }
    ]
  },
  {
    id: 'isr',
    title: 'ISR',
    link: ROUTES.ISR,
    icon: <WbSunnyOutlinedIcon />,
    visible: (portalType: PortalTypes, roles: SiteRoles[]) =>
      portalType === PortalTypes.Site && roles.includes(SiteRoles.ISRManager)
  },
  {
    id: 'admin',
    title: 'Administration',
    icon: <AdminPanelSettingsOutlinedIcon />,
    visible: (portalType: PortalTypes, roles: SiteRoles[]) =>
      portalType === PortalTypes.Site && roles.includes(SiteRoles.Admin),
    items: [
      {
        id: 'mismatches',
        title: 'Mismatches',
        link: ROUTES.ADMIN_MISMATCHES
      },
      {
        id: 'video',
        title: 'Video',
        link: ROUTES.ADMIN_VIDEO
      }
    ]
  },
  {
    id: 'gate-view-only',
    title: 'Gate',
    icon: <LocalShippingOutlinedIcon />,
    link: ROUTES.GATE_TRANSACTIONS,
    visible: (portalType: PortalTypes, roles: SiteRoles[]) =>
      portalType === PortalTypes.Site && roles.includes(SiteRoles.ViewOnly)
  },
  {
    id: 'users',
    title: 'Users',
    link: ROUTES.USERS,
    icon: <GroupOutlinedIcon />,
    visible: (portalType: PortalTypes, roles: EnterpriseRoles[]) =>
      portalType === PortalTypes.Enterprise &&
      roles.includes(EnterpriseRoles.Admin)
  }
]
