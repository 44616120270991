import { Chart } from '@/features/mismatches'

import styles from './MismatchesCharts.module.scss'

const MismatchesCharts = () => (
  <div className={styles.chartsContainer}>
    <Chart title="System" description="Ask Travis for definition" value={92} />
    <Chart
      title="PU LPN"
      description={
        <>
          % LPN matches <br /> manual entry
        </>
      }
      value={92}
    />
    <Chart title="US DOT" description="% DOT matches manual entry" value={92} />
    <Chart
      title="Detection Event"
      description="% we are capturing an event"
      value={92}
    />
    <Chart
      title="Unique Detention Event"
      description="% we are not generating duplicates"
      value={92}
    />
    <Chart
      title="Valid Detection Event"
      description={
        <>
          % of detection that was not listed at <br /> “garbage”
        </>
      }
      value={68}
    />
  </div>
)

export default MismatchesCharts
