import * as Sentry from '@sentry/react'
import { useEffect, useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useMobileNavigationContext } from '@/components/contexts'
import { Navigation } from '@/components/organisms'
import { ErrorTemplate } from '@/components/templates'
import { ROUTES, useNavigator } from '@/router'
import { useStore } from '@/store'
import { ErrorType } from '@/types/enums/ui'
import { navigationItems } from '@/utils/data/navigationItems'

import styles from './MainTemplate.module.scss'

const MainTemplate = () => {
  const location = useLocation()
  const navigate = useNavigator()

  const path = location.pathname

  const { selectedPortal } = useStore((store) => store.user)
  const { toggleMobileNavigation } = useMobileNavigationContext()

  const allowedLinks: string[] = useMemo(
    () =>
      selectedPortal?.id && selectedPortal?.roles
        ? navigationItems.reduce((links: string[], navItem) => {
            const visible = navItem.visible(
              selectedPortal.type,
              selectedPortal.roles
            )

            if (!visible) return links

            if (navItem.link) {
              links.push(navItem.link)
            }

            if (Array.isArray(navItem.items)) {
              links.push(...navItem.items.map((item) => item.link))
            }

            return links
          }, [])
        : [],
    [selectedPortal]
  )

  useEffect(() => {
    toggleMobileNavigation(false)

    if ([ROUTES.PROFILE, ROUTES.HELP].includes(location.pathname)) return

    if (!allowedLinks.includes(location.pathname)) {
      navigate.to(allowedLinks?.length ? allowedLinks[0] : '/')
    }
  }, [location.pathname, allowedLinks])

  return (
    <div className={styles.wrapper}>
      <Navigation />

      <Sentry.ErrorBoundary
        key={path}
        fallback={<ErrorTemplate type={ErrorType.Generic} />}
      >
        <Outlet />
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default MainTemplate
