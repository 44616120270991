import { FC } from 'react'

import { Checkbox, Col, ReadOnlyFormValue, Row } from '@/components/atoms'
import { IDriverItem } from '@/features/gate/types'

interface IProps {
  item: IDriverItem
}

const CargoDetailsReadOnly: FC<IProps> = (props) => {
  const { item } = props

  const {
    assetType,
    assetName,
    assetCarrier,
    chassisName,
    sealNumber,
    sealMatchPW,
    arrivingLoadStatus,
    shipmentNumber
  } = item

  return (
    <Col gap={20}>
      <ReadOnlyFormValue title="Asset Type" value={assetType} />

      <Row gap={16}>
        <ReadOnlyFormValue title="Asset ID" value={assetName} />
        <ReadOnlyFormValue title="Asset Carrier" value={assetCarrier} />
      </Row>

      <Row gap={16}>
        <ReadOnlyFormValue
          title="Arriving Load Status"
          value={arrivingLoadStatus}
        />
        <ReadOnlyFormValue title="Arriving Shipment #" value={shipmentNumber} />
      </Row>

      <Col gap={4}>
        <ReadOnlyFormValue title="Seal #" value={sealNumber} />
        <Checkbox
          disabled
          value={sealMatchPW}
          label="The seal matches the paperwork "
        />
      </Col>

      <ReadOnlyFormValue title="Chassis ID" value={chassisName} />
    </Col>
  )
}

export default CargoDetailsReadOnly
