import 'dayjs/plugin/isBetween'

import dayjs from 'dayjs'

import { TableFilterType } from '@/types/enums/table'
import { IFilterItem, IFilterValues } from '@/types/interfaces/table'

const filter = (filters: IFilterItem[], values: IFilterValues, rows: any[]) => {
  if (!rows?.length) return []

  const isFilterApplied = Object.values(values || {}).some(
    (filterValue) => !!filterValue
  )

  if (!isFilterApplied) return rows

  return rows.filter((row) => {
    let valid = true

    Object.entries(values).forEach(([key, value]) => {
      if (!value || !valid) return

      const currentFilter = filters.find((i) => i.id === key)

      if (!currentFilter) return

      switch (currentFilter.type) {
        case TableFilterType.List: {
          let found = false

          if (currentFilter.valuesMapper) {
            found = value.some(
              (valueKey: string) =>
                row[currentFilter.id] ===
                currentFilter?.valuesMapper?.[valueKey]
            )
          } else {
            found = Array.isArray(row[currentFilter.id])
              ? row[currentFilter.id]?.some?.(
                  (item: any) =>
                    value.includes(item?.id) || value.includes(item)
                )
              : value.includes(row[currentFilter.id])
          }

          if (!found) {
            valid = false
          }

          break
        }

        case TableFilterType.GroupedList: {
          let tempValid = false

          Object.entries(value).forEach(([groupKey, filterValue]) => {
            if (!filterValue) return

            const found = row[groupKey]?.some?.((item: any) =>
              (filterValue as string[]).includes(item)
            )

            if (found) {
              tempValid = true
            }
          })

          valid = tempValid
          break
        }

        case TableFilterType.DateRange: {
          if (typeof value === 'string') {
            valid = !!currentFilter.extraOptions
              ?.find((option) => option.id === value)
              ?.isValid(row)

            return
          }

          if (!row[key] && !currentFilter.fieldsToValidate) {
            valid = false
            return
          }

          if (value.startDate && value.endDate) {
            const validateFunc = (fieldKey: string) =>
              dayjs(row[fieldKey]).isBetween(
                value.startDate.startOf('day'),
                value.endDate.endOf('date')
              )

            valid = currentFilter.fieldsToValidate
              ? currentFilter.fieldsToValidate.some(validateFunc)
              : validateFunc(key)

            return
          }

          const validateFunc = (fieldKey: string) =>
            value.startDate
              ? dayjs(row[fieldKey]).isAfter(value.startDate.startOf('day'))
              : dayjs(row[fieldKey]).isBefore(value.endDate.endOf('day'))

          if (currentFilter.fieldsToValidate) {
            valid = currentFilter.fieldsToValidate.some(validateFunc)
            return
          }

          valid = validateFunc(key)
          break
        }

        case TableFilterType.Time: {
          const rowValue = row[key]

          if (!rowValue) {
            valid = false
            return
          }

          const hours = value.hour()
          const minutes = value.minute()

          const milliseconds = hours * 60 * 60 * 1000 + minutes * 60 * 1000

          valid = row[key] > milliseconds
          break
        }

        default: {
          break
        }
      }
    })

    return valid
  })
}

export default filter
